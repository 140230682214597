import styled from 'styled-components';

export const SentimentContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.3rem;
  width: fit-content;
  span {
    font-size: 0.75rem;
    font-weight: 600;
    color: ${(props) =>
      props.sentiment === 'positive'
        ? '#00b929'
        : props.sentiment === 'negative'
        ? '#FF6888'
        : '#959595'};
    text-transform: capitalize;
  }
`;

export const SentiTextwpr = styled.div`
  color: #999;
  font-size: 0.75rem;
  font-weight: 500;
`;
