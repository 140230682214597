import React from 'react';

const AllMatchIcon = () => {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_7751_161654"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="21"
        height="21"
      >
        <rect x="0.974609" y="0.395996" width="20" height="20" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_7751_161654)">
        <path
          d="M8.09961 13.896L10.8913 10.396L10.451 10.9385C10.1576 11.3002 9.83903 11.6997 9.49544 12.1372C9.15186 12.5747 8.83332 12.9772 8.53984 13.3447L8.09961 13.896ZM4.97461 15.396L8.97461 10.396L4.97461 5.396H13.4954C13.647 5.396 13.789 5.43072 13.9216 5.50016C14.0542 5.56961 14.1691 5.65988 14.2663 5.771L17.8288 10.2085C17.4677 10.0835 17.11 9.99322 16.7559 9.93766C16.4017 9.88211 16.0371 9.86822 15.6621 9.896L13.2454 6.896H8.09961L10.8913 10.396L8.09961 13.896H10.6829C10.6135 14.1321 10.5614 14.3752 10.5267 14.6252C10.492 14.8752 10.4746 15.1321 10.4746 15.396H4.97461ZM15.3913 17.146L18.0996 14.3335L17.3704 13.646L15.3645 15.7085L14.5579 14.896L13.8496 15.6043L15.3913 17.146ZM15.9699 19.396C14.8619 19.396 13.9191 19.0055 13.1413 18.2246C12.3635 17.4437 11.9746 16.4993 11.9746 15.3913C11.9746 14.2833 12.3651 13.3404 13.146 12.5627C13.9269 11.7849 14.8713 11.396 15.9793 11.396C17.0873 11.396 18.0302 11.7865 18.8079 12.5674C19.5857 13.3483 19.9746 14.2927 19.9746 15.4007C19.9746 16.5087 19.5842 17.4516 18.8032 18.2293C18.0223 19.0071 17.0779 19.396 15.9699 19.396Z"
          fill="#585858"
        />
      </g>
    </svg>
  );
};

export default AllMatchIcon;
