import React, { useState } from 'react';
import { IconWrp, RotateWrp, ToolBarWrp } from './index.sc';
import Proptypes from 'prop-types';
import ToolBarSplit from '../../../../../assets/icons/ToolBarSplit';
import ToolBarArrowUp from '../../../../../assets/icons/ToolBarArrowUp';
import ToolBarTrash from '../../../../../assets/icons/ToolBarTrash';
import DashboardPopup from '../../../../../components/dasboard-popup';
import CustomConfirmationPopUp from '../../../../../components/customize-confirmation-popup';

const ToolBar = ({ splitRow, arrowClick, deleteClick, type }) => {
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

  const handleDeleteClick = () => {
    setShowDeleteConfirmation(true);
  };

  const handleDeleteConfirmation = (confirmed) => {
    setShowDeleteConfirmation(false);
    if (confirmed) {
      deleteClick();
    }
  };

  return (
    <>
      <ToolBarWrp>
        {type !== 'article' && (
          <IconWrp onClick={splitRow}>
            <ToolBarSplit />
          </IconWrp>
        )}
        <IconWrp onClick={() => arrowClick('up')}>
          <ToolBarArrowUp />
        </IconWrp>
        <RotateWrp onClick={() => arrowClick('down')}>
          <ToolBarArrowUp />
        </RotateWrp>
        <IconWrp onClick={handleDeleteClick}>
          <ToolBarTrash />
        </IconWrp>
      </ToolBarWrp>
      <DashboardPopup
        open={showDeleteConfirmation}
        toggler={setShowDeleteConfirmation}
        popContent={
          <CustomConfirmationPopUp
            Heading="Delete Confirmation"
            SecondHeading={`Are you sure you want to delete ${type.replace(
              /-/g,
              ' '
            )} ?`}
            toggler={(confirmed) => handleDeleteConfirmation(confirmed)}
            handleDelete={deleteClick}
            actionLabel="Delete"
          />
        }
        padding="0"
        borderRadius="0.625rem"
        width="35rem"
      />
    </>
  );
};

ToolBar.propTypes = {
  splitRow: Proptypes.func,
  arrowClick: Proptypes.func,
  deleteClick: Proptypes.func,
  type: Proptypes.string,
};

export default ToolBar;
