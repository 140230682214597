import { configureStore } from '@reduxjs/toolkit';
import userSlice from './slices/userSlice';
import dashbaordSlice from './slices/dashboardSlice';
import themeSlice from './slices/themeSlice';
import searchSlice from './slices/searchSlice';

const storedUserData = localStorage.getItem('data');
const storedToken = localStorage.getItem('token');

const initialUserState = {
  data: storedUserData ? JSON.parse(storedUserData) : null,
  token: storedToken || '',
};

const store = configureStore({
  reducer: {
    user: userSlice,
    dashboard: dashbaordSlice,
    theme: themeSlice,
    search: searchSlice,
  },
  preloadedState: {
    user: initialUserState,
  },
});

export default store;
