import XCircle from './x-circle.svg';
import NpCustomis from './np_customize.svg';
import DarkLogo from './bg/DarkLogo.svg';
import Rect1 from './loginImg/Rect1.png';
import Rect2 from './loginImg/Rect2.png';
import Rect3 from './loginImg/Rect3.png';

export const Img = {
  XCircle,
  NpCustomis,
  DarkLogo,
  Rect1,
  Rect2,
  Rect3,
};
