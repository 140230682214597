import styled from 'styled-components';
import { Img } from '../../assets/img';
import { Link } from 'react-router-dom';
// import { style } from 'd3';

export const LoginContrwpr = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  gap: 2.75rem;
`;

export const LoginBgImagewpr = styled.div`
  width: 55%;
  background: #eceff9;
  height: 100%;
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  justify-content: center;
`;

export const Informationwpr = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  display: flex;
  align-items: flex-start;
  justify-content: center;
`;

export const Boldtextwpr = styled.h1`
  color: #000;
  margin: 0;
  font-family: Inter;
  font-size: 2.3125rem;
  font-style: normal;
  font-weight: 700;
  line-height: 2.75rem;
  letter-spacing: -0.04625rem;
`;

export const LightTxtwpr = styled.p`
  color: #656b8a;
  font-family: Inter;
  font-size: 1.0625rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.25rem; /* 117.647% */
  letter-spacing: -0.02125rem;
  margin: 0;
`;

export const LoginFormwpr = styled.div`
  width: 40%;
  padding: 3rem 5rem 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  /* gap: 6rem; */
`;

export const LogoBox = styled.div`
  display: flex;
  gap: 0.7rem;
`;

export const LogoTextwpr = styled.div`
  color: #000;
  font-family: Inter;
  font-size: 1.375rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

export const LogoImgwpr = styled.img`
  width: fit-content;
  height: fit-content;
`;

export const LoginFormBox = styled.div`
  width: 100%;
`;

export const LoginForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
`;

export const FormBoldtxt = styled.div`
  color: #000;
  font-family: Inter;
  font-size: 1.6875rem;
  font-style: normal;
  font-weight: 700;
  line-height: 2rem;
  letter-spacing: -0.03375rem;
  margin-bottom: 0.5rem;
`;

export const FormGraytxt = styled.div`
  color: var(--grey-grey-2, #585858);
  font-family: Inter;
  font-size: 1.0625rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.25rem;
  letter-spacing: -0.02125rem;
  margin-bottom: 1.5rem;
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
  margin-bottom: -0.5rem;
`;

export const Input = styled.input`
  width: 100%;
  min-height: 2.5rem;
  border: 1px solid #c3c7d9;
  border-radius: 0.37rem;
  outline: none;
  padding: 0.5rem 0.8rem;
  display: flex;
  align-items: center;
  font-weight: 500;
`;

export const FormLightTxt = styled.div`
  color: #161a34;
  font-family: Inter;
  font-size: 0.8125rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.25rem;
  letter-spacing: -0.01625rem;
`;

export const ErrorTxt = styled.div`
  font-size: 0.75rem;
  font-weight: 400;
  color: red;
  height: 0.75rem;
`;

export const LightGray = styled(Link)`
  text-decoration: none;
  font-size: 0.75rem;
  color: #585858;
  font-weight: 400;
  &:hover {
    cursor: pointer;
    color: ${({ theme }) => theme.primary};
  }
`;

export const SlidesWrpr = styled.div`
  width: auto;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 6.35rem;
`;

export const SliderImage1 = styled.div`
  width: 31.5625rem;
  height: 20.3125rem;
  background-image: url(${Img.Rect1});
  background-size: cover;
`;
export const SliderImage2 = styled.div`
  width: 31.5625rem;
  height: 20.3125rem;
  background-image: url(${Img.Rect2});
  background-size: cover;
`;
export const SliderImage3 = styled.div`
  width: 31.5625rem;
  height: 20.3125rem;
  background-image: url(${Img.Rect3});
  background-size: cover;
`;

export const Checkbox = styled.input`
  accent-color: ${({ theme }) => theme.primary};
  height: 1rem;
  width: 1rem;
  margin: 0;
`;

export const CheckboxText = styled.label`
  color: #161a34;
  font-family: Inter;
  font-size: 0.8125rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.25rem;
  letter-spacing: -0.01625rem;
`;
