import styled from 'styled-components/macro';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const InputRow = styled.div`
  display: flex;
  gap: 0.63rem;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  padding-top: 1rem;
  align-items: flex-end;
`;

export const BrandWrapper = styled.div`
  width: 66%;
`;
export const PeopleWrapper = styled.div`
  width: 33%;
`;
