import React, { useEffect, useRef, useState } from 'react';
import Proptypes, { object } from 'prop-types';
import {
  CheckBox,
  ChipText,
  ContentHeader,
  ContentsContainer,
  Contentwrpr,
  DeleteMainWrp,
  DeleteTextWpr,
  Deswrpr,
  DropDownWrp,
  EditIconWrp,
  EditOption,
  Elewrp,
  FramRightpr,
  FrameIconwrpr,
  Frametxt,
  Framewrpr,
  Framleftpr,
  Iconwrpr,
  InputSearchBar,
  ItemIconWrp,
  ItemWrp,
  LeftWrp,
  MainBox,
  RightSideWrp,
  SearchIconWrp,
  SearchIconwpr,
  SharedIconCir,
  SharedIconsCont,
  TextWrp,
  Textwrpr,
  TitleHeadingWrp,
  Titlewrpr,
  TopHeader,
} from './index.sc';
import { SearchDone } from '../../assets/icons/SearchDone';
// import { Save } from '../../assets/icons/Save';
import { VerticleDots } from '../../assets/icons/VerticalDots';
// import { Triangle } from '../../assets/icons/Triangle';
import { debounce } from '../../constants/debounce';
import { dashboardChips } from '../../constants/dashboard';
import ChevronDown from '../../assets/icons/ChevronDown';
import SearchIcon2 from '../../assets/icons/SearchIcon2';
import DownPolygon from '../../assets/icons/DownPolygon';
import OptionDropdwn from '../option-dropdown';
import { dashbordtypeList } from '../DashbordList/mock';
import XCirlcle from '../../assets/icons/XCirlcle';
import BookMarkIcon2 from '../../assets/icons/BookMarkIcon2';
import { theme } from '../../constants/theme';
import { useSelector } from 'react-redux';
import Delete1 from '../../assets/icons/Delete1';

export const color = ['#8D84F0', '#FF8582', '#FEC572'];

export const ContentBox = ({
  data,
  checkeditems,
  isPopup,
  Frames,
  isCheckBox = true,
  handleCheckedItems,
  handleFilter,
  handleLoad,
  pageLimit = 10,
  isLoading = false,
  isIcons = true,
  bookMardClick,
  dotClick,
  toggler,
  isNewsletter = false,
  handleClick = () => {},
  titleClick = () => {},
  savedIcon = false,
  isTriangle = false,
  showRecent = false,
  sortBy = '',
  orderBy = '',
  dashboardType = 'Recent',
  setDashboardType = () => {},
  setSearchType = () => {},
  showChip = true,
  searchQuery = '',
  bookmarkedItems = [],
  handleBookmarkedItems = () => {},
  handleDelete = () => {},
  handleEditDelete = () => {},
  setSavePopup = () => {},
  setSeleteditem = () => {},
}) => {
  const [allChecked, setAllChecked] = useState(false);
  // const [allBookmarked, setAllBookmarked] = useState(false);
  const length = data?.pages[0].data.total;
  const [show, setShow] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const [showEditOptions, setShowEditOptions] = useState(false);
  const [seletedOption, setSelectedOption] = useState(null);
  const selectedTheme = useSelector((store) => {
    return store?.theme.theme || {};
  });

  const compareParameter = isNewsletter ? 'newsLetterId' : 'id';

  useEffect(() => {
    setAllChecked(false);
  }, [data?.pages]);

  useEffect(() => {
    let items = [];
    for (let i = 0; i < data?.pages?.length; i++) {
      const page = data.pages?.[i];
      items = [...items, ...page.data.data];
    }
    const filteredItems = items.filter((obj) => obj.bookmarked === true);
    handleBookmarkedItems(filteredItems);
  }, [data?.pages, handleBookmarkedItems]);

  const checkboxHandler = (item) => {
    item.checked = !item.checked;
    if (
      checkeditems.some(
        (obj) => obj[compareParameter] === item[compareParameter]
      )
    ) {
      handleCheckedItems(
        checkeditems.filter(
          (obj) => obj[compareParameter] !== item[compareParameter]
        )
      );
      setAllChecked(false);
    } else {
      handleCheckedItems([...checkeditems, item]);
      if (
        [...checkeditems, item].length === length ||
        [...checkeditems, item].length === data.pageParams.length * pageLimit
      ) {
        setAllChecked(true);
      }
    }
  };

  const bookmarkHandler = (item) => {
    const arrCopy = [...bookmarkedItems];
    const index = arrCopy.findIndex(
      (obj) => obj[compareParameter] === item[compareParameter]
    );

    if (index !== -1 && arrCopy[index]?.bookmarked) {
      handleBookmarkedItems(arrCopy.filter((obj, i) => i !== index));
    } else {
      const obj = {
        ...item,
        bookmarked: true,
      };
      handleBookmarkedItems([...arrCopy, obj]);
    }
  };

  const handleAllcheck = (isChecked) => {
    if (isChecked) {
      let items = [];
      for (let i = 0; i < data.pages?.length; i++) {
        const page = data.pages[i];
        items = [...items, ...page.data.data];
        items.map((obj) => ({ ...obj, checked: true }));
      }
      handleCheckedItems([...items]);
      setAllChecked(true);
    } else {
      handleCheckedItems([]);
      setAllChecked(false);
    }
  };

  const handleContentClick = (e, item) => {
    e.stopPropagation();
    handleClick(item);
    if (toggler) toggler(false);
  };

  const handleFrameClick = (e, item, click, type) => {
    e.stopPropagation();
    if (click) {
      click(item, type);
    }
  };

  const listBoxRef = useRef(null);
  const inputRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      const handleLoadClick = debounce(() => {
        handleLoad();
      }, 1000);
      const { scrollTop, scrollHeight, clientHeight } = listBoxRef.current;
      if (
        scrollTop + clientHeight >= scrollHeight - 1 &&
        data?.pages?.length * 10 < length
      ) {
        handleLoadClick();
      }
    };

    const currentListBoxRef = listBoxRef.current;

    currentListBoxRef.addEventListener('scroll', handleScroll);

    return () => {
      currentListBoxRef.removeEventListener('scroll', handleScroll);
    };
  }, [handleLoad, data?.pages?.length, length]);

  const handleDashboard = (item) => {
    setShow(false);
    setDashboardType(item.value);
  };
  const toggleDropdown = () => {
    setShowSearch(!showSearch);
    setTimeout(() => {
      setShow(false);
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }, 300);
  };
  const tooltipRef = useRef(null);
  // for closing the edit/delete div if we click outside the div
  useEffect(() => {
    function handleClickOutside() {
      setShowEditOptions(false);
    }
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return (
    <>
      {showRecent && (
        <>
          <TopHeader>
            <LeftWrp onClick={() => setShow(!show)}>
              {!isNewsletter && <ChevronDown color="#656B8A" />}

              <TextWrp>{dashboardType || 'Recent'}</TextWrp>
            </LeftWrp>
            <>
              <RightSideWrp>
                {showSearch && (
                  <>
                    <InputSearchBar
                      onChange={(e) => setSearchType(e.target.value)}
                      ref={inputRef}
                      value={searchQuery}
                      type="text"
                      placeholder="Search"
                    />
                    <div
                      onClick={() => {
                        setSearchType('');
                        setShowSearch(false);
                      }}
                    >
                      <XCirlcle width={'1.2rem'} height="1.2rem" />
                    </div>
                  </>
                )}
                <SearchIconWrp onClick={toggleDropdown}>
                  <SearchIcon2
                    color="#656B8A"
                    width="1.25rem"
                    height="1.25rem"
                  />
                </SearchIconWrp>
              </RightSideWrp>
            </>
            <DropDownWrp>
              {show && !isNewsletter && (
                <OptionDropdwn
                  items={dashbordtypeList}
                  activeIndex={
                    dashbordtypeList.findIndex(
                      (item) => item.value === dashboardType
                    ) || 0
                  }
                  handleClick={handleDashboard}
                />
              )}
            </DropDownWrp>
          </TopHeader>
        </>
      )}
      <ContentHeader isNewsletter={isNewsletter}>
        <Framleftpr isPopup={isPopup}>
          {isCheckBox && (
            <CheckBox
              type="checkbox"
              checked={allChecked}
              onChange={() => handleAllcheck(!allChecked)}
            />
          )}
          <Framewrpr onClick={() => handleFilter(Frames[0].type)} point={true}>
            <Frametxt isActive={sortBy === Frames[0].type && orderBy !== ''}>
              {Frames[0].label}
            </Frametxt>
            {isTriangle && (
              <DownPolygon
                fill={
                  sortBy === Frames[0].type && orderBy !== ''
                    ? '#675EF2'
                    : '#585858'
                }
                isOpen={sortBy === Frames[0].type && orderBy === 'asc'}
              />
            )}
          </Framewrpr>
        </Framleftpr>
        <FramRightpr isPopup={isPopup}>
          {Frames.slice(1, Frames?.length).map((frame, i) => (
            <Framewrpr
              key={i}
              point={frame?.type !== 'sharedwith'}
              onClick={() => {
                if (frame?.type !== 'sharedwith') {
                  handleFilter(frame.type);
                }
              }}
            >
              <Frametxt isActive={sortBy === frame.type && orderBy !== ''}>
                {frame.label}
              </Frametxt>
              {isTriangle && frame?.type !== 'sharedwith' && (
                <DownPolygon
                  fill={
                    sortBy === frame.type && orderBy !== ''
                      ? '#675EF2'
                      : '#585858'
                  }
                  isOpen={sortBy === frame.type && orderBy === 'asc'}
                />
              )}
            </Framewrpr>
          ))}
          {isIcons && (
            <FrameIconwrpr onClick={() => handleFilter('Bookmark')}>
              {savedIcon && (
                <BookMarkIcon2
                  color={
                    sortBy === 'Bookmark' && orderBy !== ''
                      ? theme[selectedTheme].primary
                      : 'white'
                  }
                  stroke={
                    sortBy === 'Bookmark' && orderBy !== ''
                      ? theme[selectedTheme].primary
                      : '#585858'
                  }
                />
              )}
            </FrameIconwrpr>
          )}
          {dotClick && <FrameIconwrpr dotClick={dotClick}></FrameIconwrpr>}
        </FramRightpr>
      </ContentHeader>
      <ContentsContainer ref={listBoxRef}>
        {!isLoading
          ? data?.pages?.map((page, i) =>
              page?.data?.data.map((content, j) => (
                <Contentwrpr
                  onClick={(e) => handleContentClick(e, content)}
                  isPopup={isPopup}
                  key={j}
                >
                  <MainBox isPopup={isPopup}>
                    {isCheckBox && (
                      <CheckBox
                        checked={checkeditems?.some(
                          (obj) =>
                            obj[compareParameter] === content[compareParameter]
                        )}
                        onChange={() => checkboxHandler(content)}
                        type="checkbox"
                      />
                    )}
                    {false && (
                      <SearchIconwpr>
                        <SearchDone />
                      </SearchIconwpr>
                    )}
                    {content.titleNode ? (
                      content.titleNode
                    ) : (
                      <Textwrpr>
                        <TitleHeadingWrp>
                          <Titlewrpr onClick={() => titleClick(content)}>
                            {content.title}
                          </Titlewrpr>
                          {showChip && (
                            <ChipText
                              bgcolor={
                                dashboardChips[content.dashboardType]?.color
                              }
                            >
                              {dashboardChips[content.dashboardType]?.label}
                            </ChipText>
                          )}
                        </TitleHeadingWrp>
                        <Deswrpr>{content.des}</Deswrpr>
                      </Textwrpr>
                    )}
                  </MainBox>
                  <FramRightpr isPopup={isPopup}>
                    {Frames.slice(1, Frames?.length - 1).map((frame, i) =>
                      content[frame.type] &&
                      !(typeof content[frame.type] === 'object') &&
                      !Array.isArray(content[frame.type]) ? (
                        <ItemWrp key={`${i}`} isNewsletter={isNewsletter}>
                          <Elewrp
                            onClick={(e) =>
                              handleFrameClick(
                                e,
                                content,
                                frame.frameClick,
                                frame.type
                              )
                            }
                            key={i}
                          >
                            {content[frame.type]}
                          </Elewrp>
                        </ItemWrp>
                      ) : (
                        <ItemWrp key={`${i}`}>
                          <Elewrp
                            onClick={(e) =>
                              handleFrameClick(
                                e,
                                content,
                                frame.frameClick,
                                frame.type
                              )
                            }
                            key={i}
                          >
                            {content[frame.type]?.dateType} :{' '}
                            {content[frame.type]?.value.join(', ')}
                          </Elewrp>
                        </ItemWrp>
                      )
                    )}
                    {content?.sharedwith && (
                      <ItemWrp>
                        <SharedIconsCont>
                          {content?.sharedwith?.map((ele, i) => {
                            if (i < 3) {
                              return (
                                <SharedIconCir
                                  key={i}
                                  backgroundColor={color[i]}
                                >
                                  {ele.content}
                                </SharedIconCir>
                              );
                            }
                            return '';
                          })}
                          {content?.sharedwith?.length - 3 !== 0 && (
                            <SharedIconCir backgroundColor="#999999">
                              +{content?.sharedwith?.length - 3}
                            </SharedIconCir>
                          )}
                        </SharedIconsCont>
                      </ItemWrp>
                    )}
                    {isIcons && (
                      // <Elewrp width="2rem">
                      <>
                        <ItemIconWrp>
                          {bookMardClick && (
                            <Iconwrpr onClick={() => bookmarkHandler(content)}>
                              <BookMarkIcon2
                                color={
                                  bookmarkedItems?.some(
                                    (obj) =>
                                      obj[compareParameter] ===
                                      content[compareParameter]
                                  )
                                    ? theme[selectedTheme].primary
                                    : 'white'
                                }
                                stroke={
                                  bookmarkedItems?.some(
                                    (obj) =>
                                      obj[compareParameter] ===
                                      content[compareParameter]
                                  )
                                    ? theme[selectedTheme].primary
                                    : '#585858'
                                }
                              />
                            </Iconwrpr>
                          )}
                        </ItemIconWrp>
                        <ItemIconWrp>
                          {dotClick && (
                            <Iconwrpr
                              onClick={() => {
                                setShowEditOptions((prev) => !prev);
                                setSelectedOption(j);
                              }}
                            >
                              <VerticleDots />
                            </Iconwrpr>
                          )}
                          {showEditOptions && seletedOption === j && (
                            <EditIconWrp
                              open={showEditOptions}
                              ref={tooltipRef}
                            >
                              <EditOption
                                onClick={() => {
                                  setSavePopup(true);
                                  setSeleteditem(content);
                                }}
                              >
                                Edit
                              </EditOption>
                              <EditOption
                                onClick={() => handleEditDelete(content)}
                              >
                                Delete
                              </EditOption>
                            </EditIconWrp>
                          )}
                        </ItemIconWrp>
                      </>
                      // </Elewrp>
                    )}
                  </FramRightpr>
                </Contentwrpr>
              ))
            )
          : 'Loading...'}
      </ContentsContainer>
      {checkeditems.length > 0 && (
        <DeleteMainWrp onClick={() => handleDelete(checkeditems)}>
          <Delete1 color="white" />
          <DeleteTextWpr>Delete</DeleteTextWpr>
        </DeleteMainWrp>
      )}
    </>
  );
};

ContentBox.propTypes = {
  data: Proptypes.oneOfType([
    Proptypes.object,
    Proptypes.arrayOf(object),
    Proptypes.string,
  ]),
  handleLoad: Proptypes.func,
  checkeditems: Proptypes.arrayOf(object),
  isPopup: Proptypes.bool,
  isCheckBox: Proptypes.bool,
  Frames: Proptypes.arrayOf(object),
  handleCheckedItems: Proptypes.func,
  handleFilter: Proptypes.func,
  pageLimit: Proptypes.number,
  isLoading: Proptypes.bool,
  isIcons: Proptypes.bool,
  dotClick: Proptypes.bool,
  bookMardClick: Proptypes.bool,
  handleClick: Proptypes.func,
  toggler: Proptypes.func,
  titleClick: Proptypes.func,
  isNewsletter: Proptypes.bool,
  savedIcon: Proptypes.bool,
  isTriangle: Proptypes.bool,
  showRecent: Proptypes.bool,
  orderBy: Proptypes.string,
  sortBy: Proptypes.string,
  dashboardType: Proptypes.string,
  searchQuery: Proptypes.string,
  setDashboardType: Proptypes.func,
  setSearchType: Proptypes.func,
  bookmarkedItems: Proptypes.arrayOf(object),
  handleBookmarkedItems: Proptypes.func,
  showChip: Proptypes.bool,
  handleDelete: Proptypes.func,
  handleEditDelete: Proptypes.func,
  setSavePopup: Proptypes.func,
  setSeleteditem: Proptypes.func,
};
