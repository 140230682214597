import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUserDetails } from '../../redux/slices/userSlice';
import { useLocation, useNavigate } from 'react-router';
import PropTypes from 'prop-types';
import { theme } from '../../constants/theme';

import {
  Boldtextwpr,
  Checkbox,
  CheckboxText,
  ErrorTxt,
  FormBoldtxt,
  FormGraytxt,
  FormLightTxt,
  Informationwpr,
  Input,
  InputContainer,
  LightGray,
  LightTxtwpr,
  LoginBgImagewpr,
  LoginContrwpr,
  LoginForm,
  LoginFormBox,
  LoginFormwpr,
  LogoBox,
  LogoImgwpr,
  LogoTextwpr,
  SliderImage1,
  SliderImage2,
  SliderImage3,
  SlidesWrpr,
} from './index.sc';
import { Img } from '../../assets/img';
import { ValidateEmail } from './validateEmail';
import Carousel from '../../components/carousel';
import DashboardPopup from '../../components/dasboard-popup';
import ContactUs from '../../components/contact-us';
import { Button } from '../../components/button';

const InputBox = ({ type, handleChange, label, error }) => {
  return (
    <InputContainer>
      <FormLightTxt>{label}</FormLightTxt>
      <Input type={type} onChange={(e) => handleChange(e.target.value)} />
      <ErrorTxt>{error}</ErrorTxt>
    </InputContainer>
  );
};

InputBox.propTypes = {
  type: PropTypes.string,
  handleChange: PropTypes.func,
  label: PropTypes.string,
  error: PropTypes.string,
};

const LoginPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || '/';
  const dispatch = useDispatch();
  const token = useSelector((store) => {
    return store?.user?.data?.token || '';
  });

  const selectedTheme = useSelector((store) => {
    return store?.theme.theme || {};
  });

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  // const [emailError, setEmailError] = useState('');
  // const [passwordError, setPasswordError] = useState('');

  const submitLoginDetails = () => {
    dispatch(
      getUserDetails({
        username: email,
        password,
      })
    );
  };

  const user = useSelector((store) => {
    return store?.user || {};
  });

  useEffect(() => {
    if (token !== '') {
      navigate(from, { replace: true });
    } else {
      navigate('/login');
    }
  }, [from, navigate, token]);

  const submitHandler = (event) => {
    event.preventDefault();
    console.log('submitted');
    console.log('Email: ', email, ' ,Password: ', password);
    if (ValidateEmail(email) && email && password) {
      // setEmailError('');
      // setPasswordError('');
      submitLoginDetails();
      // } else {
      //   setEmailError('Invalid email!');
    }
  };

  const [newCanvasPopCont, setNewCanvasPopCont] = useState(false);
  const updateParentStateCont = (newValue) => {
    setNewCanvasPopCont(newValue);
  };

  return (
    <>
      <LoginContrwpr>
        <LoginBgImagewpr>
          <div style={{ width: '31.6125rem', height: 'auto' }}>
            <Carousel
              dotSize={'0.75rem'}
              dotBorder={'#000'}
              dotBgColor={'#000'}
              Gap={'6.35rem'}
              marginTop={'100px'}
              backgroundColor={'transparent'}
              slides={[
                {
                  component: (
                    <SlidesWrpr>
                      <SliderImage1 />
                      <Informationwpr>
                        <Boldtextwpr>
                          Build Analytics with Intelligence
                        </Boldtextwpr>
                        <LightTxtwpr>
                          Lorem ipsum dolor sit amet consectetur. Neque eu
                          turpis convallis in dictumst. Vel lacus faucibus
                          tempor et.
                        </LightTxtwpr>
                      </Informationwpr>
                    </SlidesWrpr>
                  ),
                },
                {
                  component: (
                    <SlidesWrpr>
                      <SliderImage2 />
                      <Informationwpr>
                        <Boldtextwpr>
                          Build Analytics with Intelligence-S
                        </Boldtextwpr>
                        <LightTxtwpr>
                          Lorem ipsum dolor sit amet consectetur. Neque eu
                          turpis convallis in dictumst. Vel lacus faucibus
                          tempor et.
                        </LightTxtwpr>
                      </Informationwpr>
                    </SlidesWrpr>
                  ),
                },
                {
                  component: (
                    <SlidesWrpr>
                      <SliderImage3 />
                      <Informationwpr>
                        <Boldtextwpr>
                          Build Analytics with Intelligence_D
                        </Boldtextwpr>
                        <LightTxtwpr>
                          Lorem ipsum dolor sit amet consectetur. Neque eu
                          turpis convallis in dictumst. Vel lacus faucibus
                          tempor et.
                        </LightTxtwpr>
                      </Informationwpr>
                    </SlidesWrpr>
                  ),
                },
              ]}
            />
          </div>
        </LoginBgImagewpr>
        <LoginFormwpr>
          <LogoBox>
            <LogoImgwpr src={Img.DarkLogo} />
            <LogoTextwpr>AlphametricX</LogoTextwpr>
          </LogoBox>
          <div
            style={{
              height: '100%',
              display: 'flex',
              FlexDirection: 'coloumn',
              justifyContent: 'center',
              alignItems: 'center',
              marginBottom: '100px',
            }}
          >
            <LoginFormBox>
              <FormBoldtxt>Welcome!</FormBoldtxt>
              <FormGraytxt>Login to Alphametricx</FormGraytxt>
              <LoginForm onSubmit={submitHandler}>
                <InputBox
                  label="Registered Email"
                  handleChange={setEmail}
                  // error={emailError}
                />
                <InputBox
                  label="Password"
                  type="password"
                  handleChange={setPassword}
                  // error={passwordError}
                />
                {typeof user.message === 'string' && user.message && (
                  <ErrorTxt>{user.message}</ErrorTxt>
                )}
                <div
                  style={{
                    display: 'flex',
                    gap: '0.5rem',
                    alignItems: 'center',
                  }}
                >
                  <Checkbox type="checkbox" />
                  <CheckboxText>Remember me?</CheckboxText>
                </div>

                <Button
                  title={'Login'}
                  backgroundColor={theme[selectedTheme].primary}
                  btnStyle={{ width: '100%' }}
                  onClick={submitLoginDetails}
                />
                <LightGray onClick={() => setNewCanvasPopCont(true)}>
                  Trouble Logging in?
                </LightGray>
              </LoginForm>
            </LoginFormBox>
          </div>
        </LoginFormwpr>
      </LoginContrwpr>
      <DashboardPopup
        open={newCanvasPopCont}
        toggler={setNewCanvasPopCont}
        popContent={
          <ContactUs
            toggler={setNewCanvasPopCont}
            updateParentStateCont={updateParentStateCont}
          />
        }
        padding="0"
        Cross={false}
        borderRadius="0.75rem"
        width={'auto'}
      ></DashboardPopup>
    </>
  );
};

export default LoginPage;
