import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components/macro';
import { Link } from 'react-router-dom';
import Comment from '../../assets/icons/Comment';
import { Bottomkeys, bottomDetails } from '../../constants';
import SentimentComponent from '../sentiment';
import TagIcon from '../../assets/icons/TagIcon';
import ThemeIcon from '../../assets/icons/ThemeIcon';
import { useSelector } from 'react-redux';
import { theme } from '../../constants/theme';
import GrowthIcon from '../../assets/icons/GrowthIcon';
import CommentBox from './hover-poovers/CommentBox';
import Popover from './hover-poovers';
import TagBox from './hover-poovers/TagBox';
import KeywordPopover from '../keyword-popover';
import CheckboxIcon from '../../assets/icons/CheckboxIcon';
import { formatNumber } from '../../utils';
import PortalTooltip from '../portal-tooltip';
import Tooltip from '../icon-tooltip';
import BookMarkIcon2 from '../../assets/icons/BookMarkIcon2';
import { axiosPutRequest } from '../../service';
import { useMutation } from '@tanstack/react-query';

const ArticlesWrp = styled.div`
  padding: 1rem 1.25rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  height: calc(100% - 2rem);
  overflow: auto;
  padding-bottom: 21rem;
  justify-content: space-between;
  ${({ articleView }) =>
    articleView &&
    css`
      width: 100%;
      gap: 1rem;
      margin: auto;
      flex-direction: row;
      flex-wrap: wrap;
    `}

  &::-webkit-scrollbar {
    display: none;
  }
`;

const ArticleMainWrp = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  border-bottom: 1px solid #ccc;
  /* padding-bottom: 0.25rem; */
  ${({ articleView }) =>
    articleView &&
    css`
      display: flex;
      gap: 1rem;
      width: calc(50% - 1rem);
      min-height: 13rem;
    `}/* &:hover > .article-bottom {
    opacity: 1;
  } */
`;
const ArticleWrp = styled.div`
  display: flex;
  gap: 1rem;
  position: relative;
  justify-content: space-between;
  width: 100%;
`;

const ArticlewrpBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 1rem;
  padding: 0 0 0.7rem 0;
`;
export const ArticleLinkSection = styled.div`
  display: flex;
  gap: 0.6rem;
  padding: 0 0 0.8rem 0;
`;

const ArticleWrpL = styled.div`
  width: 9.375rem;
  height: 9.375rem;
  ${({ articleView }) =>
    articleView &&
    css`
      width: 7rem;
      height: 7rem;
    `}
`;
const ArticleWrpR = styled.div`
  width: 75%;
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  ${({ articleView }) =>
    articleView &&
    css`
      width: calc(100% - 8rem);
    `}
`;

const ArticlewprZ = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const ArticleImg = styled.div`
  width: 100%;
  height: 100%;
  background-image: ${({ src }) => `url(${src})`};
  background-size: cover;
  background-position: inherit;
`;
const ArticleTitle = styled(Link)`
  font-weight: 600;
  display: -webkit-box;
  font-size: 1rem;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${({ theme }) => theme.secondaryText};
  font-family: Inter;
  font-style: normal;
  line-height: 1.5rem;
  letter-spacing: -0.32px;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
    color: ${({ theme }) => theme.primary};
  }
`;
const ArticleBody = styled.div`
  font-size: 0.75rem;
  color: ${({ theme }) => theme.closeButton};
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  line-height: 1.1875rem;
  letter-spacing: -0.24px;
`;
const ArticleLinkWrp = styled.div`
  display: flex;
  align-items: center;
  column-gap: 0.3rem;
  width: 100%;
  flex-wrap: wrap;
`;

const Articlekeytxt = styled.div`
  text-decoration: none;
  margin-right: 0.1rem;
  color: ${({ theme }) => theme.closeButton};
  font-weight: 500;
  font-size: 0.75rem;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 8rem;
  font-family: Inter;
  font-style: normal;
  line-height: 1rem;
  letter-spacing: -0.24px;
`;

const ArticleIcon = styled.img`
  width: 2rem;
  height: 2rem;
  object-fit: cover;
`;
const BoxArticlewpr = styled.div`
  display: flex;
  gap: 0.5rem;
`;

const ArticleLink = styled(Link)`
  text-decoration: none;
  color: ${({ theme }) => theme.closeButton};
  font-weight: 500;
  font-size: 0.75rem;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 10rem;
  font-family: Inter;
  font-style: normal;
  line-height: 1rem;
  letter-spacing: -0.24px;
  display: inline-block;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;
const ArticleBottomWrp = styled.div`
  /* opacity: ${({ articleView }) => (articleView ? '1' : '0')}; */
  /* position: ${({ articleView }) =>
    articleView ? 'relative' : 'absolute'}; */
  /* width: 1.25rem; */
  /* height: 100%; */
  /* padding: 0.5rem; */
  background: #ffffff;
  ${({ articleView }) =>
    !articleView &&
    css`
      /* bottom: 0; */
      /* border-top: 1px solid #ccc; */
      /* border-bottom: 1px solid #ccc; */
    `}

  display: flex;
  /* flex-direction: column; */
  /* gap: 0.5rem; */
  transition: all 400ms ease-in-out;
  /* position: relative; */
`;
const ArticleTextDetailsWrp = styled.div`
  display: flex;
  gap: 0.25rem 0.5rem;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  /* overflow-x: scroll; */
  &::-webkit-scrollbar {
    display: none;
  }

  ${({ articleView }) =>
    articleView &&
    css`
      flex-wrap: wrap;
      gap: 1rem;
    `}
`;
const ArticleActionDetailsWrp = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.75rem;
  justify-content: center;
`;
const ArticleTextWrp = styled.div`
  display: flex;
  font-size: 0.75rem;
  align-items: center;
  gap: 0.25rem;
`;
const ArticleTextLabel = styled.div`
  color: ${({ theme }) => theme.closeButton};
  font-family: Inter;
  font-size: 0.8rem;
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.26px;
  display: flex;
  flex-wrap: nowrap;
  white-space: nowrap;
`;
const ArticleTextValue = styled.div`
  position: relative;
  color: ${({ theme, itemType }) =>
    itemType === 'syndication' || itemType === 'matches'
      ? theme.primary
      : theme.closeButton};
  font-size: 0.8rem;
  font-weight: 700;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  &:hover {
    cursor: ${({ itemType }) =>
      (itemType === 'syndication' || itemType === 'matches') && 'pointer'};
  }
`;
const KeywordPopoverWrap = styled.div``;

const ArticleIconWrp = styled.div`
  position: relative;
  cursor: pointer;
  svg {
    width: 100%;
  }
`;
const IconMarker = styled.div`
  position: absolute;
  top: -5px;
  right: 0;
  background-color: ${({ theme }) => theme.primary};
  border-radius: 50%;
  height: 0.4rem;
  width: 0.4rem;
`;

const CheckBoxWrp = styled.div`
  padding-top: 0.2rem;
  &:hover {
    cursor: pointer;
  }
`;
// const ArticleWrpR = styled.div``;

const IconsPop = ({
  Icon,
  PopContent,
  data,
  width,
  height,
  tooltipText,
  mark,
}) => {
  const [show, setShow] = useState(false);
  const iconPopRef = useRef(null);
  const selectedTheme = useSelector((store) => {
    return store?.theme.theme || {};
  });

  const handleClickOutside = (event) => {
    if (iconPopRef.current && !iconPopRef.current.contains(event.target)) {
      setShow(false);
      document.removeEventListener('click', handleClickOutside);
    }
  };
  document.addEventListener('click', handleClickOutside);
  const handleShow = (e) => {
    e.stopPropagation();
    setShow(!show);
  };

  return (
    <>
      <ArticleIconWrp onClick={handleShow} ref={iconPopRef}>
        {show && (
          <Popover
            InnerChild={PopContent}
            data={data || {}}
            setShow={setShow}
          />
        )}
        <Tooltip content={tooltipText}>
          <Icon
            color={theme[selectedTheme].text}
            width={width}
            height={height}
          />
        </Tooltip>

        {mark && <IconMarker />}
      </ArticleIconWrp>
    </>
  );
};

IconsPop.propTypes = {
  Icon: PropTypes.any,
  PopContent: PropTypes.any,
  data: PropTypes.any,
  width: PropTypes.string,
  height: PropTypes.string,
  tooltipText: PropTypes.string,
  mark: PropTypes.bool,
};

const Articles = ({
  articles,
  articleView,
  page,
  type,
  showTab,
  floatingPagination,
  handleChecked,
  checked,
}) => {
  const selectedTheme = useSelector((store) => {
    return store?.theme.theme || {};
  });
  const [showPopover, setShowPopover] = useState(false);
  const [popoverPosition, setPopoverPosition] = useState({ top: 0, left: 0 });

  const handlePopoverEnter = (event) => {
    const { top, left } = event.target.getBoundingClientRect();
    setShowPopover(true);
    setPopoverPosition({
      left: left - 80,
      top: top + 5,
    });
  };

  const updateBookmark = (payload) => {
    return axiosPutRequest('/articles', {}, payload);
  };

  const { mutate: addBookmark } = useMutation({ mutationFn: updateBookmark });

  const handleBookmark = (ele) => {
    const { bookmark, id } = ele;
    const payload = {
      bookmark: !bookmark,
      id,
    };
    addBookmark(payload);
  };

  const handlePopoverLeave = () => {
    setShowPopover(false);
  };

  const handleFormatNumber = (num) => {
    const { value, suffix } = formatNumber(num, true, true);
    return value + suffix;
  };
  return (
    <ArticlesWrp articleView={articleView}>
      {articles.map((ele, i) => (
        <ArticleMainWrp articleView={articleView} key={i}>
          <ArticlewrpBox>
            {showTab && (
              <ArticleBottomWrp
                articleView={articleView}
                className="article-bottom"
              >
                <ArticleActionDetailsWrp>
                  <CheckBoxWrp onClick={() => handleChecked(i)}>
                    <CheckboxIcon
                      width="1.5rem"
                      height="1.5rem"
                      color={
                        checked.includes(i)
                          ? theme[selectedTheme].primary
                          : 'white'
                      }
                      borderColor={
                        checked.includes(i)
                          ? theme[selectedTheme].primary
                          : '#5C5E60'
                      }
                    />
                  </CheckBoxWrp>

                  <IconsPop
                    Icon={Comment}
                    PopContent={CommentBox}
                    data={{ ele, type, page }}
                    width="1.75rem"
                    height="1.75rem"
                    tooltipText="Comment"
                    mark={ele.comment !== ''}
                  />

                  <ArticleIconWrp>
                    <Tooltip content="Themes">
                      <ThemeIcon
                        color={theme[selectedTheme].text}
                        width="1.5rem"
                        height="1.5rem"
                      />
                    </Tooltip>
                  </ArticleIconWrp>

                  <IconsPop
                    Icon={TagIcon}
                    PopContent={TagBox}
                    data={{ ele, type, page }}
                    width="1.75rem"
                    height="1.75rem"
                    tooltipText="Tags"
                    mark={
                      ele?.tags.split(',').filter((tag) => tag !== '')
                        .length !== 0
                    }
                  />

                  <ArticleIconWrp>
                    <Tooltip content="Trending">
                      <GrowthIcon
                        color={theme[selectedTheme].text}
                        width="1.5rem"
                        height="1.5rem"
                      />
                    </Tooltip>
                  </ArticleIconWrp>
                  <ArticleIconWrp
                    onClick={() => {
                      handleBookmark(ele);
                    }}
                  >
                    <Tooltip content="Bookmark">
                      <BookMarkIcon2
                        color={ele?.bookmarked ? '#675ef2' : 'white'}
                        stroke={ele?.bookmarked ? '#675ef2' : '#585858'}
                        width="1.5rem"
                        height="1.5rem"
                      />
                    </Tooltip>
                  </ArticleIconWrp>
                </ArticleActionDetailsWrp>
              </ArticleBottomWrp>
            )}
            <ArticleWrp articleView={articleView} key={`${ele.title}-i`}>
              <ArticleWrpR articleView={articleView}>
                <ArticleLinkSection>
                  <ArticleIcon key={i} src={ele.icon} />
                  <ArticleLinkWrp articleView={articleView}>
                    {Bottomkeys.filter((items) => items.value === 'link').map(
                      (item, i) => (
                        <BoxArticlewpr key={i}>
                          <ArticleLink
                            to={ele[item.value]}
                            target="_blank"
                            rel={ele.title}
                          >
                            {ele.link}
                          </ArticleLink>
                        </BoxArticlewpr>
                      )
                    )}
                    {Bottomkeys.filter((item) => item.value !== 'link').map(
                      (item, i) => (
                        <BoxArticlewpr key={i}>
                          <Articlekeytxt>{ele[item.value]},</Articlekeytxt>
                        </BoxArticlewpr>
                      )
                    )}
                  </ArticleLinkWrp>
                </ArticleLinkSection>
                <ArticleTitle to={ele.link} target="_blank" rel={ele.title}>
                  {ele.title}
                </ArticleTitle>
                <ArticleBody>{ele.content}</ArticleBody>
              </ArticleWrpR>
              <ArticleWrpL articleView={articleView}>
                <ArticleImg src={ele.image} />
              </ArticleWrpL>
            </ArticleWrp>
            <ArticlewprZ>
              <ArticleTextDetailsWrp
                articleView={articleView}
                floatingPagination={floatingPagination}
              >
                {bottomDetails.map((item, j) => (
                  <ArticleTextWrp key={j} articleView={articleView}>
                    <ArticleTextLabel>{item.icon}</ArticleTextLabel>
                    <ArticleTextValue
                      articleView={articleView}
                      itemType={item.value}
                    >
                      {item.value === 'matches' ? (
                        <KeywordPopoverWrap
                          onMouseEnter={handlePopoverEnter}
                          onMouseLeave={handlePopoverLeave}
                        >
                          {ele[item.value].length || ''}
                        </KeywordPopoverWrap>
                      ) : item.value === 'sentiment' ? (
                        <SentimentComponent sentiment={ele[item.value]} />
                      ) : (
                        handleFormatNumber(ele[item.value])
                      )}
                      {item.value === 'matches' ? (
                        <PortalTooltip
                          isOpen={showPopover}
                          pos={popoverPosition}
                          align={
                            popoverPosition.left > window.innerWidth / 2
                              ? 'left'
                              : 'right'
                          }
                          vAlign={
                            popoverPosition.top > window.innerHeight / 2
                              ? 'top'
                              : 'bottom'
                          }
                          boxShadow="0px 0px 0px rgba(0, 0, 0, 0.20)"
                          borderRadius="0.3125rem"
                        >
                          <KeywordPopover items={ele[item.value]} />
                        </PortalTooltip>
                      ) : (
                        <></>
                      )}
                    </ArticleTextValue>
                    {item.value !== 'sentiment' &&
                      (item.value === 'matches' ? (
                        <ArticleTextLabel
                          onMouseEnter={handlePopoverEnter}
                          onMouseLeave={handlePopoverLeave}
                        >
                          {item.label}
                        </ArticleTextLabel>
                      ) : (
                        <ArticleTextLabel>{item.label}</ArticleTextLabel>
                      ))}
                  </ArticleTextWrp>
                ))}
              </ArticleTextDetailsWrp>
            </ArticlewprZ>
          </ArticlewrpBox>
        </ArticleMainWrp>
      ))}
    </ArticlesWrp>
  );
};

Articles.propTypes = {
  articles: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      content: PropTypes.string,
      link: PropTypes.string,
      icon: PropTypes.string,
      image: PropTypes.string,
      matches: PropTypes.arrayOf(PropTypes.string),
      reach: PropTypes.number,
      syndication: PropTypes.number,
      ave: PropTypes.number,
      sentiment: PropTypes.string,
    })
  ).isRequired,
  articleView: PropTypes.bool,
  page: PropTypes.number,
  type: PropTypes.string,
  showTab: PropTypes.bool,
  floatingPagination: PropTypes.bool,
  tooltipText: PropTypes.string,
  handleChecked: PropTypes.func,
  checked: PropTypes.func,
};

export default Articles;
