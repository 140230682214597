import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  AddContentContainer,
  AddContentWrp,
  AddItemContainer,
  AddText,
  ComponentWrapper,
  ItemContainer,
  KeywordItem,
  KeywordValue,
  MainContent,
} from '../brand/index.sc';

import { Button } from '../../../../button';
import { theme } from '../../../../../constants/theme';
import { useSelector } from 'react-redux';
import Done from '../../../../../assets/icons/Done';
import AddKeyword from '../AddKeyword';
import Close from '../../../../../assets/icons/Close';
import { IconWrp } from '../index.sc';

const People = (props) => {
  const { tabKeywords, handlePeople, hideAnalyze, handler } = props;
  const [peopleData, setPeopleData] = useState(
    tabKeywords?.peopleKeywords || []
  );

  const selectedTheme = useSelector((store) => {
    return store?.theme.theme || {};
  });

  useEffect(() => {
    setPeopleData(tabKeywords?.peopleKeywords || []);
  }, [tabKeywords]);

  const handleAnalyze = () => {
    handler(peopleData);
  };
  const handleReceiveKeyword = (data) => {
    const finalData = [...peopleData, data];
    setPeopleData(finalData);
    hideAnalyze && handlePeople?.({ peopleData: finalData });
  };

  const handleRemoveKeyword = (idx) => {
    const updatedArray = [...peopleData].filter((ele, i) => i !== idx);
    setPeopleData(updatedArray);
    hideAnalyze && handlePeople?.({ peopleData: updatedArray });
  };

  const btnStyle = {
    width: '6.6rem',
    height: '2.75rem',
    borderRadius: '0.313rem',
  };

  const disabledStyle = {
    background: '#C3C7D9',
    color: theme[selectedTheme].logoText,
    cursor: 'not-allowed',
  };
  const [hoverData, setHoverData] = useState();
  const handleShowCloseIcon = (item, i) => {
    setHoverData(i + item);
  };
  const handleHideCloseIcon = () => {
    setHoverData();
  };
  return (
    <ComponentWrapper>
      <MainContent>
        <ItemContainer>
          <AddContentWrp style={{ width: hideAnalyze && '100%' }}>
            <AddContentContainer>
              <AddText>Add Person Name</AddText>
              <AddItemContainer>
                {peopleData?.map((item, i) => (
                  <KeywordItem
                    key={i}
                    onMouseEnter={() => handleShowCloseIcon(item, i)}
                    onMouseLeave={() => handleHideCloseIcon(item, i)}
                  >
                    <KeywordValue>{item}</KeywordValue>
                    {hoverData === i + item ? (
                      <IconWrp onClick={() => handleRemoveKeyword(i)}>
                        <Close
                          color={theme[selectedTheme].primary}
                          width="14"
                          height="14"
                        />
                      </IconWrp>
                    ) : (
                      <IconWrp>
                        <Done />
                      </IconWrp>
                    )}
                  </KeywordItem>
                ))}
                <AddKeyword handleSendKeyword={handleReceiveKeyword} />
              </AddItemContainer>
            </AddContentContainer>
          </AddContentWrp>
          {!hideAnalyze && (
            <Button
              title="Analyze"
              backgroundColor={theme[selectedTheme].primary}
              color={theme[selectedTheme].logoText}
              onClick={handleAnalyze}
              btnStyle={btnStyle}
              disable={peopleData?.length === 0}
              disableStyle={disabledStyle}
            />
          )}
        </ItemContainer>
      </MainContent>
    </ComponentWrapper>
  );
};

export default People;

People.propTypes = {
  tabKeywords: PropTypes.object,
  handlePeople: PropTypes.func,
  hideAnalyze: PropTypes.bool,
  handler: PropTypes.func,
};

People.defaultProps = {
  tabKeywords: [],
};
