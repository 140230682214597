import styled from 'styled-components';

export const GraphWrp = styled.div`
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 24.735rem;
  width: 100%;
  background: ${({ theme }) => theme.background};
`;

export const GraphContainer = styled.div`
  width: 100%;
  height: 100%;
  // padding: 4rem 1.625rem;
  cursor: pointer;
`;
