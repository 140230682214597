import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const ArticlewprZ = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;
export const ArticleWrapContainer = styled.div`
  padding: 1rem 2rem 1rem 1.25rem;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  height: calc(100% - 2rem);
  overflow: auto;
  justify-content: space-between;
`;

export const ArticleImg = styled.img`
  width: 100%;
  height: 100%;
`;

export const ArticleLinkWrp = styled.div`
  display: flex;
  align-items: center;
`;

export const Articlekeytxt = styled.div`
  text-decoration: none;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--grey-grey-2, #585858);
  font-family: Inter;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1rem; /* 133.333% */
  letter-spacing: -0.015rem;
`;

export const ArticleIcon = styled.img`
  width: 0.9rem;
  height: 0.9rem;
`;
export const BoxArticlewpr = styled.div`
  display: flex;
  gap: 0.5rem;
`;

export const ArticleLink = styled(Link)`
  text-decoration: none;
  color: var(--grey-grey-2, #585858);
  font-family: Inter;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1rem;
  letter-spacing: -0.015rem;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 5rem;
`;

export const ArticleWrap = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  gap: 0.9rem;
  width: 100%;
  height: 9rem;
  &:hover {
    cursor: pointer;
    .article-tool-bar-wrp {
      visibility: visible;
    }
  }
`;
export const ArticleImageWrap = styled.div`
  width: 20%;
  height: 9rem;
  background-image: url(${({ imageUrl }) => imageUrl});
  background-size: cover;
  position: relative;
`;
export const ArticleContentWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  width: 80%;
  position: relative;
`;

export const ActicleContentTitle = styled.div`
  color: #000;
  font-family: Inter;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.5rem;
  letter-spacing: -0.02rem;
`;
export const ActicleContentDescription = styled.div`
  color: var(--grey-grey-2, #585858);
  font-family: Inter;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.1875rem;
  letter-spacing: -0.015rem;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  text-overflow: ellipsis;
`;

export const ArticleDescriptionWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
`;
export const SelectedKey = styled.span`
  color: var(--primary-8676-ff, #675ef2);
  font-family: Inter;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.5rem;
  letter-spacing: -0.02rem;
`;
export const SelectedWord = styled.span`
  color: var(--primary-8676-ff, #675ef2);
  font-family: Inter;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.1875rem;
  letter-spacing: -0.015rem;
`;
export const ToolBarWrp = styled.div`
  visibility: hidden;
  position: absolute;
  top: 0rem;
  right: -2rem;
  width: 3rem;
  height: 7rem;
  display: flex;
  justify-content: flex-end;
`;
