import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ReusableDropDown from '../../../Drop-down-reusable';
import {
  ButtonBoxwpr,
  ButtonWrapper,
  // ButtonTextTwo,
  // CreateDashboardButton,
  // DropdownCont,
  // DropdownItems,
  // DropdownSecondaryItem,
  // DropdownWrap,
  Heading,
  NewsLetterButton,
  Wrpr,
} from './index.sc';
import Add2 from '../../../../assets/icons/Add2';
import Advanced from '../../../../assets/icons/create-dashboard/dropdown/advanced.svg';
import Brand from '../../../../assets/icons/create-dashboard/dropdown/brand.svg';
import People from '../../../../assets/icons/create-dashboard/dropdown/people.svg';
import Industry from '../../../../assets/icons/create-dashboard/dropdown/industry.svg';
import Custom from '../../../../assets/icons/create-dashboard/dropdown/custom.svg';
// import DropDownButton from '../../../../assets/icons/DropDownButton';

import { useNavigate, useParams } from 'react-router-dom';
// import { Button } from '../../../button';
import { theme } from '../../../../constants/theme';
import { useSelector } from 'react-redux';
import CustomPopupWrapper from '../../../custom-popup-wrapper';
import CustomDashboardCanvas from '../../../custom-dashboard-canvas';
import DashboardPopup from '../../../dasboard-popup';
// import { CustomListItem } from './CustomListItem';

export default function DashboardHeaderV2({
  handleSaveDashboard = () => {},
  activeScreen,
  setConfimationAlertPopUp = () => {},
  changeSaved,
  setSelectedPath = () => {},
}) {
  const { searchId } = useParams();
  const selectedTheme = useSelector((store) => {
    return store?.theme.theme || {};
  });

  const dropdownOptions = [
    {
      label: 'Brand & Competition',
      value: 'brand',
      path: `${searchId}/brand`,
      icon: Brand,
      subText: '6 Charts',
    },
    {
      label: 'People',
      value: 'people',
      path: `${searchId}/people`,
      icon: People,
      subText: '7 Charts',
    },
    {
      label: 'Industry',
      value: 'industry',
      path: `${searchId}/industry`,
      icon: Industry,
      subText: '7 Charts',
    },
    {
      label: 'Advanced',
      value: 'advanced',
      path: `${searchId}/advanced`,
      icon: Advanced,
      subText: '5 Charts',
      children: [
        {
          label: 'Campaign Monitor',
          value: 'campaign',
          path: `${searchId}/campaign`,
        },
        {
          label: 'Author Impact',
          value: 'authorimpact',
          path: `${searchId}/authorimpact`,
        },
        {
          label: 'Sentiments By Themes',
          value: 'sentiments',
          path: `${searchId}/sentiments`,
        },
        {
          label: 'Message Congruence',
          value: 'congruence',
          path: `${searchId}/congruence`,
        },
        {
          label: 'PR Impact',
          value: 'primpact',
          path: `${searchId}/primpact`,
        },
      ],
    },
    {
      label: 'Custom',
      value: 'custom',
      path: `${searchId}/custom`,
      icon: Custom,
      subText: '24 Charts',
    },
  ];

  const navigate = useNavigate();
  const navigateNewsletter = () => {
    if (!changeSaved) {
      setConfimationAlertPopUp(true);
      setSelectedPath('/create-news-letter/100');
      return;
    }
    navigate('/create-news-letter/100');
  };
  const [customCanvas, setCustomCanvas] = useState(false);
  const createDashboard = (path) => {
    setSelectedDashboard(path.split('/')[1]);
    if (!changeSaved) {
      setConfimationAlertPopUp(true);
      setSelectedPath(`/dashboard/${path}`);
      return;
    }
    if (path.split('/')[1] === 'custom') {
      setCustomCanvas(true);
    } else {
      navigate(`/dashboard/${path}`);
    }
  };
  const childNavigation = (path) => {
    setSelectedDashboard(path.split('/')[1]);
    if (!changeSaved) {
      setConfimationAlertPopUp(true);
      setSelectedPath(`/dashboard/${path}`);
      return;
    }
    navigate(`/dashboard/${path}`);
  };

  const [selectedItems, setSelectedItems] = useState({});
  const [selectedDashboard, setSelectedDashboard] = useState('');
  const handleCheckedItems = (e) => {
    setSelectedItems(e);
  };
  return (
    <>
      <Wrpr activeScreen={activeScreen}>
        <Heading>Overview</Heading>
        <NewsLetterButton onClick={navigateNewsletter}>
          <Add2 height={'1rem'} color={'#675ef2'} />
          {/* <ButtonText>Create Newsletter</ButtonText> */}
          Create Newsletter
        </NewsLetterButton>
        <ReusableDropDown
          dropdownArray={dropdownOptions}
          label="Create Dashboard"
          frontIcon={<Add2 height={'1rem'} color={'#fff'}></Add2>}
          navigation={createDashboard}
          childNavigation={childNavigation}
        ></ReusableDropDown>
        {/* <Button
          title={'Save'}
          backgroundColor={theme[selectedTheme].primary}
          onClick={handleSaveDashboard}
          btnStyle={{ marginLeft: '0.5rem', borderRadius: '0.313rem' }}
        /> */}
        {
          <DashboardPopup
            open={customCanvas}
            toggler={setCustomCanvas}
            popContent={
              <CustomPopupWrapper
                heading="Add/Remove Charts"
                footer={
                  <ButtonWrapper>
                    <ButtonBoxwpr
                      onClick={() => {
                        setSelectedItems({});
                        setCustomCanvas(false);
                      }}
                    >
                      Cancel
                    </ButtonBoxwpr>
                    <ButtonBoxwpr
                      background={theme[selectedTheme].primary}
                      fontColor={'#fff'}
                      onClick={() => {
                        if (
                          !Object.keys(selectedItems).filter(
                            (items) =>
                              Object.keys(selectedItems[items]).filter(
                                (item) => selectedItems[items][item].checked
                              ).length
                          ).length
                        ) {
                          return;
                        }
                        navigate(
                          `/dashboard/${searchId}/${selectedDashboard}/`,
                          { state: JSON.stringify(selectedItems) }
                        );

                        setCustomCanvas(false);
                      }}
                      disabled={
                        !Object.keys(selectedItems).filter(
                          (items) =>
                            Object.keys(selectedItems[items]).filter(
                              (item) => selectedItems[items][item].checked
                            ).length
                        ).length
                      }
                    >
                      Create Dashboard
                    </ButtonBoxwpr>
                  </ButtonWrapper>
                }
              >
                <CustomDashboardCanvas
                  handleItemClick={handleCheckedItems}
                  selectedItems={selectedItems}
                ></CustomDashboardCanvas>
              </CustomPopupWrapper>
            }
            width={'auto'}
            padding="0"
            Cross={true}
            borderRadius="0.75rem"
          />
        }
      </Wrpr>
    </>
  );
}

DashboardHeaderV2.propTypes = {
  handleSaveDashboard: PropTypes.func,
  activeScreen: PropTypes.string,
  setConfimationAlertPopUp: PropTypes.func,
  setSelectedPath: PropTypes.func,
  changeSaved: PropTypes.bool,
};
