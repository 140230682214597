import React, { useEffect, useMemo, useState } from 'react';
import * as Styles from './index.sc';
import Brand from '../brand';
import People from '../people';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Button } from '../../../../button';
import { useSelector } from 'react-redux';
import { theme } from '../../../../../constants/theme';

const Custom = ({ tabKeywords, handler }) => {
  const { state } = useLocation();
  const [keywordData, setKeywordData] = useState({
    brandData: [],
    competitionData: [],
    peopleData: [],
  });
  const handleBrand = (e) => {
    setKeywordData({ ...keywordData, ...e });
  };
  const handlePeople = (e) => {
    setKeywordData({ ...keywordData, ...e });
  };
  const handleAnalyze = () => {
    handler(keywordData);
  };
  const brandKeywords = useMemo(
    () => tabKeywords?.brandKeywords || [],
    [tabKeywords?.brandKeywords]
  );
  const competitionKeywords = useMemo(
    () => tabKeywords?.competitionKeywords || [],
    [tabKeywords?.competitionKeywords]
  );
  const peopleKeywords = useMemo(
    () => tabKeywords?.peopleKeywords || [],
    [tabKeywords?.peopleKeywords]
  );
  useEffect(() => {
    setKeywordData({
      brandData: brandKeywords,
      competitionData: competitionKeywords,
      peopleData: peopleKeywords,
    });
  }, [brandKeywords, competitionKeywords, peopleKeywords]);
  const selectedTheme = useSelector((store) => {
    return store?.theme.theme || {};
  });
  const btnStyle = {
    width: '6.6rem',
    height: '2.75rem',
    borderRadius: '0.313rem',
  };

  const disabledStyle = {
    background: '#C3C7D9',
    color: theme[selectedTheme].logoText,
    cursor: 'not-allowed',
  };
  return (
    <Styles.Wrapper>
      <Styles.InputRow>
        {((JSON.parse(state)?.brand &&
          Object.keys(JSON.parse(state).brand)?.filter(
            (item) => JSON.parse(state).brand[item]
          ).length) ||
          tabKeywords?.brandKeywords?.length ||
          tabKeywords?.competitionKeywords?.length) && (
          <Styles.BrandWrapper
            style={{
              width:
                !(
                  (JSON.parse(state)?.people &&
                    Object.keys(JSON.parse(state).people)?.filter(
                      (item) => JSON.parse(state).people?.[item]
                    )?.length) ||
                  tabKeywords?.brandKeywords?.length ||
                  tabKeywords?.competitionKeywords?.length
                ) && '100%',
            }}
          >
            <Brand
              tabKeywords={{
                brandKeywords: keywordData?.brandData,
                competitionKeywords: keywordData?.competitionData,
              }}
              hideAnalyze={true}
              handleUpdates={handleBrand}
            ></Brand>
          </Styles.BrandWrapper>
        )}

        {((JSON.parse(state)?.people &&
          !!Object.keys(JSON.parse(state)?.people)?.filter(
            (item) => JSON.parse(state)?.people?.[item]
          )?.length) ||
          !!tabKeywords?.peopleKeywords?.length) && (
          <Styles.PeopleWrapper
            style={{
              width:
                !(
                  (JSON.parse(state)?.brand &&
                    Object.keys(JSON.parse(state).brand)?.filter(
                      (item) => JSON.parse(state).brand[item]
                    ).length) ||
                  tabKeywords?.peopleKeywords?.length
                ) && '100%',
            }}
          >
            <People
              handlePeople={handlePeople}
              hideAnalyze={true}
              tabKeywords={{ peopleKeywords: keywordData.peopleData }}
            ></People>
          </Styles.PeopleWrapper>
        )}
        <Styles.ButtonWrapper>
          <Button
            title="Analyze"
            backgroundColor={theme[selectedTheme].primary}
            color={theme[selectedTheme].logoText}
            onClick={handleAnalyze}
            btnStyle={btnStyle}
            disable={
              keywordData.brandData?.length === 0 ||
              keywordData.competitionData?.length === 0 ||
              (JSON.parse(state)?.people &&
                Object.keys(JSON.parse(state).people)?.filter(
                  (item) => JSON.parse(state).people?.[item]
                ).length &&
                keywordData.peopleData?.length === 0)
            }
            disableStyle={disabledStyle}
          />
        </Styles.ButtonWrapper>
      </Styles.InputRow>
    </Styles.Wrapper>
  );
};

Custom.propTypes = {
  tabKeywords: PropTypes.object,
  handler: PropTypes.func,
};

export default Custom;
