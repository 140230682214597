import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  AdvancedComponentWrapper,
  // GenerateButton,
  GenerateButtonWrapper,
} from './index.sc';
// import TabComponent from '../../../../campaign-tabs';
import Campaigns from '../../../../campaigns';
import PropTypes from 'prop-types';
import { Button } from '../../../../button';
import { theme } from '../../../../../constants/theme';
import { useSelector } from 'react-redux';

export const campaignInitial = [];
// Array.from({ length: 0 }, (_, index) => {
//   return {
//     campaign_id: new Date().getTime() + index,
//     name: '',
//     keywords: '',
//   };
// });

const Advanced = ({ handler, tabKeywords: keywords = {} }) => {
  const { dashboardType } = useParams();

  const [campaignData, setCampaignData] = useState(
    keywords?.advancedKeywords || campaignInitial
  );

  useEffect(() => {
    setCampaignData(keywords?.advancedKeywords?.slice(0, 5) || campaignInitial);
  }, [keywords]);

  const handleCampaignData = (campaignData) => {
    setCampaignData(campaignData);
  };

  const selectedTheme = useSelector((store) => {
    return store?.theme.theme || {};
  });

  return (
    <AdvancedComponentWrapper>
      {/* <TabComponent /> */}
      <Campaigns
        label={
          dashboardType === 'sentiments'
            ? 'Choose Sentiments'
            : dashboardType === 'primpact'
            ? 'PR Campaign Names'
            : dashboardType === 'congruence'
            ? 'Brand Name'
            : 'Campaign Name'
        }
        namePlaceHolder={
          dashboardType === 'congruence'
            ? 'Brand Communication name'
            : 'Enter Campaign name'
        }
        keywordsPlaceHolder={
          dashboardType === 'congruence'
            ? 'Enter specific keywords for Brand Message'
            : 'Enter specific keywords for Campaign'
        }
        campaignData={campaignData}
        handleCampaignData={handleCampaignData}
      />
      <GenerateButtonWrapper>
        {/* <GenerateButton
          disabled={campaignData.length === 0}
          onClick={() => handler(campaignData)}
        >
          Generate
        </GenerateButton> */}
        <Button
          title={'Generate'}
          backgroundColor={theme[selectedTheme].primary}
          onClick={() => handler(campaignData)}
          disabled={campaignData.length === 0}
        />
      </GenerateButtonWrapper>
    </AdvancedComponentWrapper>
  );
};

Advanced.propTypes = {
  handler: PropTypes.func,
  tabKeywords: PropTypes.object,
};

export default Advanced;
