import { useMutation, useQuery } from '@tanstack/react-query';
import { axiosGet, axiosPostRequest } from '../service';

const getArticleData = (
  limit,
  page,
  type,
  theme,
  search,
  sort,
  filter,
  id,
  widget,
  graphSelection
) => {
  return axiosGet('/articles', {
    limit: limit || 50,
    page: page * 50,
    tab: type,
    theme,
    search,
    sort,
    filter,
    id,
    widget,
    graphSelection,
  });
};

const getNewsLetterData = (newsLetterId) => {
  return axiosGet('/news-letter', {
    newsLetterId,
  });
};

const getSavedSearchData = (savedSearchId) => {
  return axiosGet('/savedSearchData', {
    savedSearchId,
  });
};

const getSearchData = (searchId) => {
  return axiosGet('/searchData', {
    searchId,
  });
};

const handleSearchPost = (payload) => {
  return axiosPostRequest('/api/search', {}, payload);
};

// const handleSearchUpdate = (payload) => {
//   return axiosPutRequest(
//     '/api/search',
//     {
//       searchId,
//     },
//     payload
//   );
// };

const getDashboardData = (searchId, dashboardType, dashboardId) => {
  return axiosGet('/dashboard-data', {
    searchId,
    dashboardType,
    dashboardId,
  });
};

const getSearchFilterData = () => {
  return axiosGet('/search-filter', {});
};

const getCustomSourcesFilter = () => {
  return axiosGet('/custom-sources', {});
};

export const useSearchData = (searchId) => {
  return useQuery({
    queryKey: ['search-data', searchId],
    queryFn: () => getSearchData(searchId),
    refetchOnWindowFocus: false,
  });
};

export const usePostSearchData = () => {
  return useMutation({ mutationFn: handleSearchPost });
};

// export const useUpdateSearchData = (searchId, onSuccess, onMutate) => {
//   return useMutation(handleSearchUpdate, {
//     onSuccess: () => {
//       onSuccess();
//     },
//     onMutate: () => {
//       onMutate();
//     },
//   });
// };

export const useDashboardData = (
  searchId,
  dashboardType,
  dependent = false,
  dashboardId
) => {
  return useQuery({
    queryKey: ['dashboard-data', searchId, dashboardType, dashboardId],
    queryFn: () => getDashboardData(searchId, dashboardType, dashboardId),
    refetchOnWindowFocus: false,
    ...(dependent && { enabled: !!searchId }),
  });
};

export const useArticleData = (
  limit,
  page,
  type,
  theme,
  searchKey,
  sort,
  filter,
  id,
  enabled = true,
  widget,
  graphSelection
) => {
  return useQuery({
    queryKey: [
      'articles',
      limit,
      page,
      type,
      theme,
      searchKey,
      sort,
      filter,
      id,
      widget,
      graphSelection,
    ],
    queryFn: () =>
      getArticleData(
        limit,
        page,
        type,
        theme,
        searchKey,
        sort,
        filter,
        id,
        widget,
        graphSelection
      ),
    refetchOnWindowFocus: false,
    enabled,
  });
};

export const useSearchFilterData = () => {
  return useQuery({
    queryKey: ['search-filter'],
    queryFn: () => getSearchFilterData(),
    refetchOnWindowFocus: false,
  });
};

export const useCustomSourcesFilterData = () => {
  return useQuery({
    queryKey: ['custom-sources'],
    queryFn: () => getCustomSourcesFilter(),
    refetchOnWindowFocus: false,
  });
};

export const useNewsLetterData = (newsLetterId) => {
  return useQuery({
    queryKey: ['newsletter-data', newsLetterId],
    queryFn: () => getNewsLetterData(newsLetterId),
    refetchOnWindowFocus: false,
  });
};

export const useSavedSearchData = (savedSearchId) => {
  return useQuery({
    queryKey: ['saved-search-data', savedSearchId],
    queryFn: () => getSavedSearchData(savedSearchId),
    refetchOnWindowFocus: false,
    enabled: !!savedSearchId,
  });
};
