import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  ArticleSection,
  // IconWrapper,
  Iconwpr,
  Paginatewpr,
  // Paginatewpr,
  SectionTitle,
  Sectionwpr,
  TabPopwpr,
  // IconWraper,
} from '../index.sc';
// import IconBox from '../IconBox';
import Tabs from '../../tabs';
import ArticleDetails from '../../articles/article-details';
import Articles from '../../articles';
// import Pagination from '../../pagination';
import { Titletabs } from '../../../constants/mock';
import { useArticleData } from '../../../hooks/useSearch';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
// import Spinner from '../../spinner';
import { TitleBox } from '../../tabs/TabTitle';
import {
  HeadingWrp,
  // LinkSpan,
  LoadingWrp,
  TabButton,
  ThemeTabs,
  Themetabtxt,
} from './index.sc';
import { theme } from '../../../constants/theme';
import DownIcon from '../../../assets/icons/DownIcon';
import { useSelector } from 'react-redux';
import { axiosGet, axiosPostRequest } from '../../../service';
import { ArticleTheme } from '../../tabs/CardTitle';
import Pagination from '../../pagination';
import IconBox, { dropdownOptions } from '../IconBox';
// import useDebounce from '../../../hooks/useDebounce';
// import XCirlcle from '../../../assets/icons/XCirlcle';
import CircularLoading from '../../../assets/icons/loading/circularLoading';
import { DashboardListwpr, RightWrp } from '../../DashbordList/index.sc';
import SortDropdown from '../SortDropdown';

const ArticleSectionComponent = ({
  activeScreen,
  articleType,
  page,
  setPage,
  type,
  setType,
  id,
  articlePosition,
  floatingPagination,
  setSelected,
  setSelectedGraph,
  setArticleTypeClose,
  setResetSelection,
  showTabs = true,
  showFullTitle = true,
  showHeading = false,
  loader = false,
}) => {
  const queryClient = useQueryClient();
  const [activeTheme, setActiveTheme] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [sortOrder, setSortOrder] = useState('');
  const [filter, setFilter] = useState('');

  const limit = 50;
  // console.log(searchQuery, 'search');

  const {
    isLoading,
    // error: articleerror,
    data,
    // isFetching,
  } = useArticleData(
    limit,
    page,
    type,
    activeTheme,
    searchQuery,
    sortOrder,
    filter,
    id,
    true,
    articleType.widget,
    articleType.graphSelection
  );
  const [titleTabs, setTitleTabs] = useState(data?.data?.tabs || Titletabs);
  const [total, setTotal] = useState();
  const [showTab, setShowTab] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [checked, setChecked] = useState([]);
  const iconBoxRef = useRef(null);
  const [sortPopUp, setSortPopUp] = useState(false);

  // eslint-disable-next-line no-unused-vars
  const { data: themeTabs, isLoading: themeTabLoading } = useQuery({
    queryKey: ['themeTab', id],
    queryFn: () => axiosGet('/article-theme', { id }),
    refetchOnWindowFocus: false,
    enabled: !!id,
  });

  const themetabs = themeTabs?.data?.data?.map((item) => {
    return { ...item, title: <ArticleTheme title={item.title} /> };
  });

  const handleTheme = (index, item) => {
    setActiveTheme(item?.title?.props?.title);
    // queryClient.invalidateQueries(['articles', 0, type]);
  };

  const handlePage = (pageNum) => {
    setPage(pageNum);
    queryClient.invalidateQueries(['articles', pageNum, type]);
  };

  const tabs = titleTabs?.map((ele, i) => ({
    ...ele,
    title: <TitleBox title={ele.label} des={ele.count} />,
    id: i,
  }));

  const handleChecked = (option) => {
    const newCategory = [...checked];
    if (checked.includes(option)) {
      newCategory.splice(checked.indexOf(option), 1);
    } else {
      newCategory.push(option);
    }
    setChecked(newCategory);
    // make a api call in seperate function
  };

  const selectedTheme = useSelector((store) => {
    return store?.theme.theme || {};
  });

  const count = data?.data?.tabs?.filter((obj) => obj.value === type)[0].count;
  // console.log(count, 'total count for pagination');

  useEffect(() => {
    if (Array.isArray(data?.data?.tabs)) setTitleTabs(data?.data?.tabs);
    if (count) {
      setTotal(count);
    }
  }, [data?.data?.tabs, count, setTotal]);

  const handleTabs = (index) => {
    setType(tabs[index].value);
    setActiveTab(index);
    setPage(0);
    queryClient.invalidateQueries(['articles', 0, tabs[index].value]);
    setShowTab(false);
  };
  // const handleClose = () => {
  //   setSelected(null);
  //   setArticleTypeClose();
  //   setSelectedGraph((prev) => prev.map((_) => true));
  //   setResetSelection(false);
  // };

  const updateBookmark = (payload) => {
    return axiosPostRequest('/articles', {}, payload);
  };

  const { mutate: mutateFunction } = useMutation({
    mutationFn: updateBookmark,
  });

  const bookMarkedArticles = data?.data?.data
    .filter((article) => article.bookmarked)
    .map((item) => item.id);
  const taggedArticles = data?.data?.data
    .filter((article) => article.tags !== '')
    .map((item) => item.id);

  const downLoadFunction = (value) => {
    mutateFunction({
      downloadType: value,
      selectedArticles:
        value === 'selected'
          ? checked
          : value === 'bookmarked'
          ? bookMarkedArticles
          : value === 'tagged'
          ? taggedArticles
          : [],
    });
  };

  const handleClickOutside = (event) => {
    if (iconBoxRef.current && !iconBoxRef.current.contains(event.target)) {
      setSortPopUp(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const handleSortClick = () => {
    setSortPopUp((prevOpen) => !prevOpen);
  };

  useEffect(() => {
    setSortOrder('relevance');
  }, []);

  const handleSort = (value) => {
    setSortOrder(value);
    setSortPopUp(false);
  };

  return (
    <>
      <ArticleSection
        activeScreen={activeScreen}
        articlePosition={articlePosition}
        className={activeScreen === 'article' ? 'active' : ''}
      >
        <Sectionwpr>
          <SectionTitle>
            {showHeading && 'All Articles'}
            {/* {(articleType.widget ||
              articleType.graphSelection ||
              floatingPagination) && (
              <> */}
            {/* {showFullTitle && articleType.widget ? (
                  <LinkSpan style={{ marginLeft: '0.25rem' }} color="#000000">
                    {articleType.widget ? '\\ ' + articleType.widget : ''}
                  </LinkSpan>
                ) : (
                  <></>
                )}
                {showFullTitle && articleType.graphSelection ? (
                  <LinkSpan color={theme.light.primary}>
                    {showFullTitle && articleType.graphSelection
                      ? '\\ ' + articleType.graphSelection
                      : articleType.graphSelection}
                  </LinkSpan>
                ) : ( */}
            {showFullTitle && (
              <HeadingWrp>{articleType?.graphSelection}</HeadingWrp>
            )}
            {/* )} */}
            {/* {activeScreen !== 'dashboard' && (
                  <IconWrapper
                    onClick={() => {
                      handleClose();
                      setSelected(null);
                    }}
                    style={{ marginLeft: '0.5rem' }}
                  >
                    <XCirlcle />
                  </IconWrapper>
                )} */}
            {/* </>
            )} */}
          </SectionTitle>
          <RightWrp>
            <DashboardListwpr
              ref={iconBoxRef}
              overflow="none"
              minWidth="none"
              width="160px"
              bgColor="none"
            >
              <TabButton
                onClick={handleSortClick}
                textColor={theme.light.closeButton}
                fontSize={'0.875rem'}
              >
                <Iconwpr
                  style={{
                    transform: sortPopUp ? 'rotate(180deg)' : 'rotate(0deg)',
                    transition: 'transform 0.3s ease',
                  }}
                >
                  <DownIcon />
                </Iconwpr>
                {sortOrder
                  .replace(/_/g, ' ')
                  .replace(/(?:^|\s)\S/g, (c) => c.toUpperCase())}
              </TabButton>
              <SortDropdown
                left="none"
                bottom="none"
                marginBottom="none"
                Open={sortPopUp}
                setIsDropdownOpen={handleSort}
                sortOrder={sortOrder}
                dropdownOptions={dropdownOptions}
              />
            </DashboardListwpr>
            <TabButton onClick={() => setShowTab(!showTab)}>
              <Iconwpr>
                <DownIcon />
              </Iconwpr>
              {titleTabs[activeTab].label}
              <span>({titleTabs[activeTab].count})</span>
            </TabButton>
          </RightWrp>
          <TabPopwpr showTab={showTab}>
            <Tabs
              items={!tabs ? [{}] : tabs}
              widthItem={activeScreen === 'article' ? '7rem' : '20%'}
              variant="underline"
              activeColor="#675EF2"
              inactiveColor="#000000"
              onChange={handleTabs}
              isContent={false}
              bottomBorderWidth="3px"
              paddingWrapper="0.75rem 1rem"
            />
          </TabPopwpr>
        </Sectionwpr>
        <ArticleDetails
          start={page * 50 + 1}
          end={(page + 1) * 50 < count ? (page + 1) * 50 : count}
          total={count}
        />
        <ThemeTabs>
          <Themetabtxt>Tags/Themes</Themetabtxt>
          <Tabs
            items={!themeTabs?.data?.data ? [{}] : themetabs}
            variant="card"
            activeColor={theme[selectedTheme].background}
            inactiveColor={theme[selectedTheme].text}
            onChange={handleTheme}
            isContent={false}
            gapitems="0.4rem"
            bottomBorderWidth="0"
            wraperBorderWidth="0"
            activeCardBGColor={theme[selectedTheme].primary}
            inactiveCardBGColor={theme[selectedTheme].background}
            cardBorderRadius=".75rem"
            paddingWrapper="0.25rem 0 0"
            defaultActive={false}
            resetTab={true}
          />
        </ThemeTabs>

        {Array.isArray(data?.data?.data) && !loader && !isLoading ? (
          <Articles
            articles={data?.data?.data}
            articleView={activeScreen === 'article'}
            type={type}
            page={page}
            showTab={showTabs}
            floatingPagination={floatingPagination}
            handleChecked={handleChecked}
            checked={checked}
          />
        ) : (
          <LoadingWrp>
            <CircularLoading
              bgColor={theme[selectedTheme].primary}
              size="0.25rem"
              width="1.875rem"
              height="1.875rem"
            />
          </LoadingWrp>
        )}
        {floatingPagination && total && (
          <Paginatewpr
            articlePosition={articlePosition}
            fullScreen={activeScreen === 'article'}
            activeScreen={activeScreen}
          >
            <IconBox
              page={page}
              type={type}
              searchQuery={searchQuery}
              setSearchQuery={setSearchQuery}
              sortOrder={sortOrder}
              setSortOrder={setSortOrder}
              downLoadFunction={downLoadFunction}
              // downloading={downloadingPdf}
              filter={filter}
              setFilter={setFilter}
              selectedLength={checked?.length}
            />
            <Pagination
              page={page}
              prevClick={handlePage}
              nextClick={handlePage}
              handlePage={handlePage}
              total={total}
              align="end"
              limit={50}
            />
          </Paginatewpr>
        )}
      </ArticleSection>
    </>
  );
};

export default ArticleSectionComponent;

ArticleSectionComponent.propTypes = {
  activeScreen: PropTypes.string,
  articleType: PropTypes.object,
  setArticleTypeClose: PropTypes.func,
  page: PropTypes.number,
  setPage: PropTypes.func,
  type: PropTypes.string,
  setType: PropTypes.func,
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  articlePosition: PropTypes.string,
  floatingPagination: PropTypes.bool,
  setSelected: PropTypes.func,
  setArticlePosition: PropTypes.func,
  setSelectedGraph: PropTypes.func,
  setResetSelection: PropTypes.func,
  showTabs: PropTypes.bool,
  showFullTitle: PropTypes.bool,
  pageWidth: PropTypes.string,
  showHeading: PropTypes.bool,
  loader: PropTypes.bool,
};
