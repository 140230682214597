import React from 'react';
import Proptypes from 'prop-types';
import {
  LegendWrp,
  SlotBody,
  SlotBodyMain,
  SlotDetailsWrp,
  TooltipTitle,
  TooltipWrapper,
} from './index.sc';
import { graphTypes, widgetMapping } from '../../../../constants/widgets';
import GraphLegend from '../../../graph-legend';

const gridXTicksCount = 6;

const generateGraphComponent = (
  widget,
  defaultConfig = {},
  type,
  dashboardType,
  canvas,
  resetSelection = false
) => {
  const widgetDetails = {
    dashboardType,
    type,
    component: widget.component,
  };

  const { bentoView } =
    (widgetMapping[dashboardType] &&
      widgetMapping[dashboardType]?.widget?.component) ||
    {};

  const GraphComponent =
    type === 'dashboard' || type === 'l2'
      ? graphTypes[widget.graphType].component
      : bentoView[type]?.component;

  const dataCount = widget?.data?.data?.length;
  const xTicksCount = dataCount < gridXTicksCount ? dataCount : gridXTicksCount;

  const canvasConfig = { gridXTicks: xTicksCount };

  const finalConfig = {
    ...(type === 'dashboard' || type === 'l2'
      ? graphTypes[widget.graphType].config
      : bentoView[type]?.config),
    ...defaultConfig,
    ...widgetDetails,
    ...(canvas && canvasConfig),
  };

  return (
    <GraphComponent
      data={widget}
      config={finalConfig}
      resetSelection={resetSelection}
    />
  );
};

const AuthorImpactTooltip = ({
  type = 'dashboard',
  dashboardType = 'overview',
  canvas = false,
  resetSelection = false,
  tooltipData,
}) => {
  const { data, rawData } = tooltipData;

  const legendData = rawData?.tooltipInfo?.labels || [];

  return (
    <>
      <TooltipWrapper>
        <TooltipTitle>{data?.label || 'title'}</TooltipTitle>
        {legendData?.length <= 6 && (
          <LegendWrp>
            <GraphLegend legendData={legendData} />
          </LegendWrp>
        )}
        <SlotDetailsWrp>
          <SlotBody>
            <SlotBodyMain>
              {generateGraphComponent(
                rawData?.tooltipInfo,
                {},
                type,
                dashboardType,
                canvas,
                resetSelection
              )}
            </SlotBodyMain>
          </SlotBody>
        </SlotDetailsWrp>
      </TooltipWrapper>
    </>
  );
};

export default AuthorImpactTooltip;

AuthorImpactTooltip.propTypes = {
  tooltipData: Proptypes.object.isRequired,
  widget: Proptypes.object,
  type: Proptypes.string,
  dashboardType: Proptypes.string,
  canvas: Proptypes.bool,
  resetSelection: Proptypes.bool,
};
