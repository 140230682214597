import React from 'react';
import PropTypes from 'prop-types';
import { DropdownWrapper, SortOption } from './index.sc';

const SortDropdown = ({
  Open,
  setIsDropdownOpen,
  sortOrder,
  dropdownOptions,
  left = '0',
  bottom = '1.5rem',
  marginBottom = '1.5rem',
  marginTop = 'none',
}) => {
  const handleSortOptionClick = (order) => {
    setIsDropdownOpen(order);
  };

  return (
    <DropdownWrapper
      isOpen={Open}
      selected={sortOrder !== ''}
      left={left}
      bottom={bottom}
      marginBottom={marginBottom}
      marginTop={marginTop}
    >
      {dropdownOptions.map((item, i) => (
        <SortOption
          selected={item.value === sortOrder}
          key={i}
          onClick={() => handleSortOptionClick(item.value)}
        >
          {item.label}
        </SortOption>
      ))}
    </DropdownWrapper>
  );
};

SortDropdown.propTypes = {
  Open: PropTypes.bool.isRequired,
  setIsDropdownOpen: PropTypes.func.isRequired,
  setSortOrder: PropTypes.func.isRequired,
  sortOrder: PropTypes.string,
  dropdownOptions: PropTypes.arrayOf(Object),

  left: PropTypes.string,
  bottom: PropTypes.string,
  marginBottom: PropTypes.string,
  marginTop: PropTypes.string,
};

export default SortDropdown;
