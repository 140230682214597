// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
  box-sizing: border-box;
  font-family: 'Inter', sans-serif;
}
body {
  margin: 0;
  overflow: hidden;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body::-webkit-scrollbar {
  display: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.x.axis > .tick > text,
.y.axis > .tick > text {
  fill: #5c5e60;
  font-size: 0.75rem;
}
.x.axis > .domain,
.y.axis > .domain {
  stroke: #d9dbde;
}

.x.axis > .tick > line:first-child,
.y.axis > .tick > line:first-child {
  display: none;
}

@keyframes ripple {
  0% {
    transform: scale(1);
  }
  75% {
    transform: scale(1.75);
    opacity: 1;
  }
  100% {
    transform: scale(2);
    opacity: 0;
  }
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,gCAAgC;AAClC;AACA;EACE,SAAS;EACT,gBAAgB;EAChB,gCAAgC;EAChC,mCAAmC;EACnC,kCAAkC;AACpC;;AAEA;EACE,aAAa;AACf;;AAEA;EACE;aACW;AACb;;AAEA;;EAEE,aAAa;EACb,kBAAkB;AACpB;AACA;;EAEE,eAAe;AACjB;;AAEA;;EAEE,aAAa;AACf;;AAEA;EACE;IACE,mBAAmB;EACrB;EACA;IACE,sBAAsB;IACtB,UAAU;EACZ;EACA;IACE,mBAAmB;IACnB,UAAU;EACZ;AACF","sourcesContent":["* {\n  box-sizing: border-box;\n  font-family: 'Inter', sans-serif;\n}\nbody {\n  margin: 0;\n  overflow: hidden;\n  font-family: 'Inter', sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n\nbody::-webkit-scrollbar {\n  display: none;\n}\n\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',\n    monospace;\n}\n\n.x.axis > .tick > text,\n.y.axis > .tick > text {\n  fill: #5c5e60;\n  font-size: 0.75rem;\n}\n.x.axis > .domain,\n.y.axis > .domain {\n  stroke: #d9dbde;\n}\n\n.x.axis > .tick > line:first-child,\n.y.axis > .tick > line:first-child {\n  display: none;\n}\n\n@keyframes ripple {\n  0% {\n    transform: scale(1);\n  }\n  75% {\n    transform: scale(1.75);\n    opacity: 1;\n  }\n  100% {\n    transform: scale(2);\n    opacity: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
