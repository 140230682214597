import React, { useState } from 'react';
import Proptypes from 'prop-types';
import {
  FullSlot,
  LegendBox,
  LegendCon,
  LegendItem,
  LegendLabel,
  SlotBody,
  SlotBodyHeader,
  SlotBodyHeaderRight,
  SlotBodyMain,
  SlotDetailsMainWrp,
  SlotDetailsWrp,
  SlotFooter,
  SlotHeader,
  SlotHeaderLeft,
  SlotTitle,
} from './index.sc';

import Loader from '../../loader';
import { ScatterPlot3D } from '../../../graphs';
import PortalTooltip from '../../portal-tooltip';
import GraphTooltip from '../../graph-tooltip';
import {
  CommentaryLabel,
  CommentarySection,
  SlotOverviewWrapper,
} from '../../search-result/index.sc';
import SlotOverview from '../../search-result/slot-details/SlotOverview';
import { gridLine } from '../../../constants/widgets';

const CampaignMonitor = ({
  widget,
  loader,
  type = 'dashboard',
  handleOnClick = () => {},
}) => {
  const [enableTooltip, setEnableTooltip] = useState(false);
  const [toolTipPos, setToolTipPos] = useState({ left: 0, top: 0 });
  const [tooltipData, setTooltipData] = useState();

  const tooltipEnabled = true;

  const handleMouseEnter = (event, d, i) => {
    if (tooltipEnabled) {
      setEnableTooltip(true);
      setToolTipPos({
        ...toolTipPos,
        left: event.clientX,
        top: event.clientY - 10,
      });
      const tData = d.data || d;
      setTooltipData({ data: tData, rawData: d?.rawData });
    }
  };

  const handleMouseMove = (event, d, i) => {
    if (tooltipEnabled) {
      setToolTipPos({
        ...toolTipPos,
        left: event.clientX,
        top: event.clientY - 10,
      });
    }
  };
  const handleMouseLeave = (event, d, i) => {
    if (tooltipEnabled) {
      setToolTipPos({
        left: 0,
        top: 0,
      });
      setEnableTooltip(false);
      setTooltipData();
    }
  };

  const defaultConfig = {
    handleMouseEnter,
    handleMouseMove,
    handleMouseLeave,
    handleOnClick,
  };

  const legendData = [
    {
      label: 'X-Axis: Net Sentiment',
      color: '#ECEFF3',
    },
    {
      label: 'Y-Axis: Reach',
      color: '#ECEFF3',
    },
    {
      label: 'Size of Bubbles = No of Articles',
      color: '#ECEFF3',
    },
  ];

  return (
    <>
      <FullSlot className="graph-widget">
        <SlotDetailsMainWrp>
          <SlotDetailsWrp>
            <SlotHeader>
              <SlotHeaderLeft>
                <SlotTitle>Campaign Monitor</SlotTitle>
              </SlotHeaderLeft>
            </SlotHeader>
            <SlotBodyHeader>
              <SlotOverviewWrapper>
                <SlotOverview summary={widget?.summary} />
              </SlotOverviewWrapper>
              <SlotBodyHeaderRight>
                <LegendCon>
                  {legendData.map((ele, i) => {
                    return (
                      <LegendItem key={i}>
                        <LegendBox legendColor={ele?.color}></LegendBox>
                        <LegendLabel>{ele?.label}</LegendLabel>
                      </LegendItem>
                    );
                  })}
                </LegendCon>
              </SlotBodyHeaderRight>
            </SlotBodyHeader>
            <SlotBody type={type} className="commentary">
              <SlotBodyMain>
                {loader ? (
                  <Loader />
                ) : (
                  widget && (
                    <ScatterPlot3D
                      data={widget}
                      config={{
                        xAxisType: 'number',
                        yLabelAlignment: 30,
                        gridXTicks: 6,
                        gridYTicks: 6,
                        valueInPercent: true,
                        ...gridLine,
                        ...defaultConfig,
                      }}
                    />
                  )
                )}
                {enableTooltip && (
                  <PortalTooltip
                    isOpen={true}
                    pos={toolTipPos}
                    align={
                      toolTipPos.left > window.innerWidth / 2 ? 'left' : 'right'
                    }
                    vAlign={
                      toolTipPos.top > window.innerHeight / 2 ? 'top' : 'bottom'
                    }
                  >
                    <GraphTooltip
                      tooltipData={tooltipData}
                      type="two-d"
                      widget={widget}
                    />
                  </PortalTooltip>
                )}
              </SlotBodyMain>
            </SlotBody>
            <SlotFooter>
              <CommentarySection>
                <CommentaryLabel>
                  Insights : Media engagement of “covid vaccine” increased by
                  22% in jan 2021
                </CommentaryLabel>
              </CommentarySection>
            </SlotFooter>
          </SlotDetailsWrp>
        </SlotDetailsMainWrp>
      </FullSlot>
    </>
  );
};

export default CampaignMonitor;

CampaignMonitor.defaultProps = {
  type: 'dashboard',
};

CampaignMonitor.propTypes = {
  widget: Proptypes.object,
  loader: Proptypes.bool,
  type: Proptypes.string,
  handleOnClick: Proptypes.func,
};
