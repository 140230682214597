import React from 'react';
import {
  TabSection,
  TabSectionWrp,
  LabelText,
  SubTextWrp,
  SubText,
  BoldText,
  TabsWrp,
  MainWrp,
  TabTitle,
  TimeText,
  ChipText,
} from './index.sc';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { dashboardChips } from '../../constants/dashboard';

export const routes = {
  recent_searches: 'search-results',
  recent_newsletters: 'news-letter',
  recent_dashboards: 'dashboard',
};

const RecentSearches = ({ items }) => {
  const navigate = useNavigate();
  function formatNumber(number) {
    if (typeof number !== 'number') {
      return 'NA';
    }

    let formattedValue = '';
    let suffix = '';

    switch (true) {
      case number >= 1e9:
        formattedValue = (number / 1e9).toFixed(1);
        suffix = ' Bn';
        break;
      case number >= 1e6:
        formattedValue = (number / 1e6).toFixed(1);
        suffix = ' Mn';
        break;
      case number >= 1e3:
        formattedValue = (number / 1e3).toFixed(1);
        suffix = ' k';
        break;
      default:
        formattedValue = number.toString();
    }
    return formattedValue + suffix;
  }

  return (
    <MainWrp>
      <TabTitle>{items.title}</TabTitle>
      <TabSectionWrp>
        <TabsWrp>
          {items?.data.map((item, i) => {
            return (
              <React.Fragment key={i}>
                <TabSection
                  onClick={() =>
                    navigate(
                      routes[items?.type] === 'dashboard'
                        ? `${routes[items?.type]}/${item.id}/${
                            item.dashboardType
                          }/${item.searchId}`
                        : routes[items?.type] === 'news-letter'
                        ? `${routes[items?.type]}/${item.newsLetterId}`
                        : `${routes[items?.type]}/${item.id}/overview/${
                            item.savedSearchId
                          }`
                    )
                  }
                  key={i}
                >
                  <div>
                    <LabelText>{item.title}</LabelText>
                    <TimeText>{item.createOn}</TimeText>
                  </div>
                  <SubTextWrp>
                    <SubText>{item.lastSearchOn}</SubText>
                    <SubText>
                      {items.title === 'Recent Searches' ? (
                        <BoldText>{formatNumber(item.result)}</BoldText>
                      ) : (
                        <ChipText
                          bgcolor={dashboardChips[item.dashboardType]?.color}
                        >
                          {dashboardChips[item.dashboardType]?.label}
                        </ChipText>
                      )}
                    </SubText>
                  </SubTextWrp>
                </TabSection>
              </React.Fragment>
            );
          })}
        </TabsWrp>
      </TabSectionWrp>
    </MainWrp>
  );
};

RecentSearches.propTypes = {
  items: PropTypes.object,
};

export default RecentSearches;
