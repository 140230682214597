import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
  AddContentContainer,
  AddContentWrp,
  AddItemContainer,
  AddText,
  ComponentWrapper,
  ItemContainer,
  KeywordItem,
  KeywordValue,
  MainContent,
  // SectionHeader,
  // SectionTitle,
} from './index.sc';
import { IconWrp } from '../index.sc';
import { Button } from '../../../../button';
import { theme } from '../../../../../constants/theme';
import { useSelector } from 'react-redux';
import Done from '../../../../../assets/icons/Done';
import AddKeyword from '../AddKeyword';
import Close from '../../../../../assets/icons/Close';

const Brand = ({ tabKeywords, hideAnalyze, handleUpdates, handler }) => {
  const brandKeywords = useMemo(
    () => tabKeywords?.brandKeywords || [],
    [tabKeywords]
  );
  const competitionKeywords = useMemo(
    () => tabKeywords?.competitionKeywords || [],
    [tabKeywords]
  );

  const [brandData, setBrandData] = useState(brandKeywords);
  const [competitionData, setCompetitionData] = useState(competitionKeywords);

  const selectedTheme = useSelector((store) => {
    return store?.theme.theme || {};
  });

  useEffect(() => {
    setBrandData(brandKeywords);
    setCompetitionData(competitionKeywords);
  }, [brandKeywords, competitionKeywords]);

  // if no analyze button, handle changes and update a state in parent

  // const handleAddRemoveChart = () => {};
  function handleAnalyze() {
    handler({ brandData, competitionData });
  }

  const handleReceiveBrandKeyword = (data) => {
    const finalData = [...brandData, data];
    setBrandData(finalData);
    hideAnalyze && handleUpdates?.({ brandData: finalData, competitionData });
  };

  const handleReceiveCompetitionKeyword = (data) => {
    const finalData = [...competitionData, data];
    setCompetitionData(finalData);
    hideAnalyze && handleUpdates?.({ brandData, competitionData: finalData });
  };

  const handleRemoveKeyword = (item, idx) => {
    let updatedArray = [];
    if (item === 'brand') {
      updatedArray = [...brandData].filter((ele, i) => i !== idx);
      setBrandData(updatedArray);
      hideAnalyze &&
        handleUpdates?.({ brandData: updatedArray, competitionData });
    } else {
      updatedArray = [...competitionData].filter((ele, i) => i !== idx);
      setCompetitionData(updatedArray);
      hideAnalyze &&
        handleUpdates?.({ brandData, competitionData: updatedArray });
    }
  };

  const disabledStyle = {
    background: '#C3C7D9',
    color: theme[selectedTheme].logoText,
    cursor: 'not-allowed',
  };
  const [hoverDataComp, setHoverDataComp] = useState();
  const [hoverDataBrand, setHoverDataBrand] = useState();

  const handleShowCloseIconComp = (item, i) => {
    setHoverDataComp(i + item);
  };
  const handleHideCloseIconComp = () => {
    setHoverDataComp();
  };
  const handleShowCloseIconBrand = (item, i) => {
    setHoverDataBrand(i + item);
  };
  const handleHideCloseIconBrand = () => {
    setHoverDataBrand();
  };
  return (
    <ComponentWrapper>
      {/* <SectionHeader>
        <SectionTitle>Brand & Competitive Dashboard</SectionTitle>
        <Button
          title="Add/Remove Charts"
          backgroundColor={theme[selectedTheme].background}
          color={theme[selectedTheme].primary}
          border={`1px solid ${theme[selectedTheme].primary}`}
          onClick={handleAddRemoveChart}
          btnStyle={{ borderRadius: '0.5rem' }}
          //   icon={<AddRemoveChart width="1rem" height="1rem" />}
        />
      </SectionHeader> */}
      <MainContent>
        <ItemContainer>
          <AddContentWrp style={{ width: hideAnalyze && '100%' }}>
            <AddContentContainer>
              <AddText>Brand Keywords</AddText>
              <AddItemContainer>
                {brandData.map((item, i) => (
                  <KeywordItem
                    key={i}
                    onMouseEnter={() => handleShowCloseIconBrand(item, i)}
                    onMouseLeave={() => handleHideCloseIconBrand(item, i)}
                  >
                    <KeywordValue>{item}</KeywordValue>
                    {hoverDataBrand === i + item ? (
                      <IconWrp onClick={() => handleRemoveKeyword('brand', i)}>
                        <Close
                          color={theme[selectedTheme].primary}
                          width="14"
                          height="14"
                        />
                      </IconWrp>
                    ) : (
                      <IconWrp>
                        <Done />
                      </IconWrp>
                    )}
                  </KeywordItem>
                ))}
                <AddKeyword handleSendKeyword={handleReceiveBrandKeyword} />
              </AddItemContainer>
            </AddContentContainer>
            <AddContentContainer>
              <AddText>Competition Keywords</AddText>
              <AddItemContainer>
                {competitionData.map((item, i) => (
                  <KeywordItem
                    key={i}
                    onMouseEnter={() => handleShowCloseIconComp(item, i)}
                    onMouseLeave={() => handleHideCloseIconComp(item, i)}
                  >
                    <KeywordValue>{item}</KeywordValue>
                    {hoverDataComp === i + item ? (
                      <IconWrp
                        onClick={() => handleRemoveKeyword('competition', i)}
                      >
                        <Close
                          color={theme[selectedTheme].primary}
                          width="14px"
                          height="14px"
                        />
                      </IconWrp>
                    ) : (
                      <IconWrp>
                        <Done width="14px" height="14px" />
                      </IconWrp>
                    )}
                  </KeywordItem>
                ))}
                <AddKeyword
                  handleSendKeyword={handleReceiveCompetitionKeyword}
                />
              </AddItemContainer>
            </AddContentContainer>
          </AddContentWrp>
          {!hideAnalyze && (
            <Button
              title={'Analyze'}
              backgroundColor={theme[selectedTheme].primary}
              onClick={handleAnalyze}
              disable={brandData.length === 0}
              disableStyle={disabledStyle}
              btnStyle={{ width: '6rem', height: '2.7rem' }}
            />
          )}
        </ItemContainer>
      </MainContent>
    </ComponentWrapper>
  );
};

export default Brand;

Brand.propTypes = {
  tabKeywords: PropTypes.any,
  hideAnalyze: PropTypes.bool,
  handleUpdates: PropTypes.func,
  handler: PropTypes.func,
};
