import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Button } from '../../button';
import { theme } from '../../../constants/theme';
import { useSelector } from 'react-redux';
import { axiosPutRequest } from '../../../service';
import { useMutation } from '@tanstack/react-query';

const Boxwpr = styled.div`
  width: 15.5rem;
  height: 8.75rem;
  background-color: ${({ theme }) => theme.background};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: end;
`;

const Inputwpr = styled.textarea`
  box-sizing: border-box;
  width: 100%;
  padding: 0.5rem 1rem 0.5rem 0.625rem;
  background: ${({ theme }) => theme.background};
  height: 6rem;
  resize: none;
  outline: none;
  border: none;
  font-weight: 400;
  font-size: 0.75rem;
  color: ${({ theme }) => theme.text};
  &::-webkit-scrollbar {
    display: none;
  }
`;

const CommentBox = ({ data, setShow }) => {
  const selectedTheme = useSelector((store) => {
    return store?.theme.theme || {};
  });
  // const queryClient = useQueryClient();
  const [text, setText] = useState(data?.ele?.comment);
  console.log(data.ele, 'this is a data');

  const payload = {
    id: data.ele.id,
    comment: text,
  };

  const addArticleTags = () => {
    return axiosPutRequest('/articles', {}, payload);
  };

  const { mutate: addText } = useMutation({
    mutationFn: addArticleTags,
  });
  const handleClick = () => {
    addText(text);
    setShow(false);
  };
  return (
    <Boxwpr>
      <Inputwpr onChange={(e) => setText(e.target.value)} defaultValue={text} />

      <Button
        title="Done"
        backgroundColor={theme[selectedTheme].primary}
        onClick={handleClick}
        disable={!text}
        disableStyle={{
          background: theme[selectedTheme].borders,
          border: 'none',
          color: theme[selectedTheme].background,
        }}
      />
    </Boxwpr>
  );
};

CommentBox.propTypes = {
  data: PropTypes.any,
  setShow: PropTypes.func,
};

export default CommentBox;
