import React, { useEffect, useRef, useState } from 'react';
import Proptypes from 'prop-types';
import {
  CommentaryLabel,
  CommentarySection,
  IconBox,
  Iconwpr,
  LegendSection,
  LineShow,
  SlotBody,
  SlotDetailsMainWrp,
  SlotDetailsWrp,
  SlotFooter,
  SlotHeader,
  SlotHeaderLeft,
  SlotOverviewWrapper,
  SlotTitle,
} from '../index.sc';

import PortalTooltip from '../../portal-tooltip';
import { graphTypes, widgetMapping } from '../../../constants/widgets';
import Edit2 from '../../../assets/icons/Edit2';
import { VerticleDots } from '../../../assets/icons/VerticleDots';
import GraphLegend from '../../graph-legend';
import GraphTooltip from '../../graph-tooltip';
import DashboardPopup from '../../dasboard-popup';
import EditGraphPopup from '../../edit-graph-popup';
import SlotOverview from './SlotOverview';
import CircularLoading from '../../../assets/icons/loading/circularLoading';
import { trimmedData } from '../../../utils';
import { LinearLine } from '../../../graphs';
import SimpleReusableDropDown from '../../simple-dropdown';
import DownloadIcon from '../../../assets/icons/DownloadIcon';

const gridXTicksCount = 6;

const generateGraphComponent = (
  widget,
  defaultConfig,
  type,
  dashboardType,
  canvas,
  resetSelection = false
) => {
  const widgetDetails = {
    dashboardType,
    type,
    component: widget.component,
  };
  const { bentoView } =
    (widgetMapping[dashboardType] &&
      widgetMapping[dashboardType][widget.component]) ||
    {};

  const GraphComponent =
    type === 'dashboard' || type === 'l2'
      ? graphTypes[widget.graphType].component
      : bentoView[type]?.component;

  const maxData =
    type === 'dashboard' || type === 'l2'
      ? graphTypes[widget.graphType].maxData
      : bentoView[type]?.maxData;

  const dataCount = widget?.data?.data?.length;
  const xTicksCount = dataCount < gridXTicksCount ? dataCount : gridXTicksCount;

  const canvasConfig = { gridXTicks: xTicksCount };

  const finalConfig = {
    ...(type === 'dashboard' || type === 'l2'
      ? graphTypes[widget.graphType].config
      : bentoView[type]?.config),
    ...defaultConfig,
    ...widgetDetails,
    ...(canvas && canvasConfig),
    ...(type === 'dashboard' ? {} : { handleOnClick: undefined }),
  };

  return (
    <GraphComponent
      data={trimmedData(widget.data, maxData)}
      config={finalConfig}
      resetSelection={resetSelection}
    />
  );
};

const SlotDetails = ({
  widget,
  loader,
  type = 'dashboard',
  dashboardType = 'overview',
  canvas = false,
  legend = false,
  commentary = false,
  resetSelection = false,
  handleOnClick = () => {},
  handleUpdatedChart = () => {},
  handleShowDownloadPopUp = () => {},
  overRideSlot,
  editOption = false,
  actionOption = false,
  downloadFunction,
  setSelectedComponent,
  graphDownloading,
}) => {
  const [enableTooltip, setEnableTooltip] = useState(false);
  const [toolTipPos, setToolTipPos] = useState({ left: 0, top: 0 });
  const [tooltipData, setTooltipData] = useState();
  const [popupIsOpen, setPopupIsOpen] = useState(false);
  const [tooltipEvent, setTooltipEvent] = useState({});
  const [openActionDropdown, setOpenActionDropDown] = useState(false);

  const downloadRef = useRef(null);

  const handleClickOutside = (event) => {
    if (downloadRef.current && !downloadRef.current.contains(event.target)) {
      setOpenActionDropDown(false);
    }
  };
  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const actionDropDownOptions = [
    {
      label: 'Download',
      icon: <DownloadIcon color="#161A34" />,

      clickFunction: downloadFunction,
    }, // Replace <Icon1 /> with your actual icon component
  ];

  const tooltipEnabled = true;

  const handleMouseEnter = (event, d, i) => {
    if (tooltipEnabled) {
      setEnableTooltip(true);
      setTooltipEvent({
        event,
        d,
        i,
      });
      setToolTipPos({
        ...toolTipPos,
        left: event.clientX,
        top: event.clientY - 10,
      });
      const tData = d.data || d;
      setTooltipData({ data: tData, rawData: d?.rawData });
    }
  };

  const handleMouseMove = (event, d, i) => {
    if (tooltipEnabled) {
      setTooltipEvent({
        event,
        d,
        i,
      });
      setToolTipPos({
        ...toolTipPos,
        left: event.clientX,
        top: event.clientY - 10,
      });
    }
  };
  const handleMouseLeave = (event, d, i) => {
    if (tooltipEnabled) {
      setTooltipEvent({});
      setToolTipPos({
        left: 0,
        top: 0,
      });
      setEnableTooltip(false);
      setTooltipData();
    }
  };

  const defaultConfig = {
    handleMouseEnter,
    handleMouseMove,
    handleMouseLeave,
    handleOnClick,
  };

  let legendData = [];
  const twoD = widget?.data?.labels?.length > 1;

  if (widget?.data?.legends?.length > 0) {
    legendData = [...widget.data.legends];
  } else {
    if (twoD) {
      legendData = widget?.data?.labels.map((ele) => ({
        label: ele?.label,
        value: ele?.label?.replaceAll(' ', '').toLowerCase(),
        color: ele?.color,
      }));
    } else {
      legendData = widget?.data?.legends?.map((ele) => ({
        label: ele?.label,
        value: ele?.value,
        color: ele?.color,
      }));
    }
  }

  const handleGraphEditClick = (e) => {
    setPopupIsOpen(!popupIsOpen);
  };

  const handleUpdateGraph = (e, data) => {
    e.stopPropagation();
    // console.log(data, 'edit data');
    handleUpdatedChart(data);
    handleGraphEditClick(e);
  };

  const handleOptionIcon = (e, componentName) => {
    e.stopPropagation();
    setSelectedComponent(componentName);
    setOpenActionDropDown(!openActionDropdown);
  };

  const showLineBar = () => {
    const { bentoView } =
      (widgetMapping[dashboardType] &&
        widgetMapping[dashboardType][widget.component]) ||
      {};

    const GraphComponent =
      type === 'dashboard' || type === 'l2'
        ? graphTypes[widget.graphType].component
        : bentoView[type]?.component;

    return GraphComponent === LinearLine;
  };

  return (
    <SlotDetailsMainWrp type={type}>
      <IconBox type={type}>
        {editOption && (
          <Iconwpr
            width={'1.5rem'}
            height={'1.5rem'}
            onClick={handleGraphEditClick}
          >
            <Edit2 />
          </Iconwpr>
        )}
        {actionOption && (
          <>
            <Iconwpr
              width={'1.5rem'}
              height={'1.5rem'}
              onClick={(e) => handleOptionIcon(e, widget.component)}
              ref={downloadRef}
            >
              <VerticleDots
                color={openActionDropdown ? '#675ef2' : '#5C5E60'}
              />
              <SimpleReusableDropDown
                isOpen={openActionDropdown}
                options={actionDropDownOptions}
                graphDownloading={graphDownloading}
                setIsOpen={setOpenActionDropDown}
              />
            </Iconwpr>
          </>
        )}
      </IconBox>
      <SlotDetailsWrp>
        <SlotHeader>
          <SlotHeaderLeft>
            <SlotTitle>{widget?.title}</SlotTitle>
          </SlotHeaderLeft>
        </SlotHeader>
        {type === 'dashboard' && (
          <SlotOverviewWrapper>
            <SlotOverview summary={widget?.data?.summary} />
          </SlotOverviewWrapper>
        )}
        <SlotBody
          type={type}
          className={`${type === 'dashboard' && legend ? 'legend' : ''} ${
            type === 'dashboard' && commentary ? 'commentary' : ''
          }`}
        >
          {loader ? (
            <CircularLoading
              size="0.25rem"
              width="1.875rem"
              height="1.875rem"
            />
          ) : (
            generateGraphComponent(
              widget,
              defaultConfig,
              type,
              dashboardType,
              canvas,
              resetSelection
            )
          )}

          {showLineBar() && Object.keys(tooltipEvent).length > 0 && (
            <LineShow xAxis={tooltipEvent?.event?.offsetX + 2}></LineShow>
          )}
          {tooltipEnabled && enableTooltip && (
            <PortalTooltip
              isOpen={true}
              pos={toolTipPos}
              align={toolTipPos.left > window.innerWidth / 2 ? 'left' : 'right'}
              vAlign={
                toolTipPos.top > window.innerHeight / 2 ? 'top' : 'bottom'
              }
            >
              <GraphTooltip
                tooltipData={tooltipData}
                type="two-d"
                widget={widget}
              />
            </PortalTooltip>
          )}
        </SlotBody>
        {(legend || commentary) && (
          <SlotFooter>
            {legend && legendData?.length <= 6 && (
              <LegendSection>
                <GraphLegend legendData={legendData} />
              </LegendSection>
            )}
            {commentary && (
              <CommentarySection>
                <CommentaryLabel>
                  Insights : Media engagement of “covid vaccine” increased by
                  22% in jan 2021
                </CommentaryLabel>
              </CommentarySection>
            )}
          </SlotFooter>
        )}
      </SlotDetailsWrp>
      <DashboardPopup
        open={popupIsOpen}
        toggler={handleGraphEditClick}
        width={'40%'}
        popContent={
          <EditGraphPopup
            popupIsOpen={popupIsOpen}
            handleEditClick={handleUpdateGraph}
            handleClose={handleGraphEditClick}
            widgetTitle={widget.title}
            widget={widget}
          />
        }
      />
    </SlotDetailsMainWrp>
  );
};

SlotDetails.defaultProps = {
  type: 'dashboard',
};

SlotDetails.propTypes = {
  widget: Proptypes.object,
  loader: Proptypes.bool,
  type: Proptypes.string,
  dashboardType: Proptypes.string,
  canvas: Proptypes.bool,
  legend: Proptypes.bool,
  commentary: Proptypes.bool,
  resetSelection: Proptypes.bool,
  handleOnClick: Proptypes.func,
  handleUpdatedChart: Proptypes.func,
  handleShowDownloadPopUp: Proptypes.func,
  overRideSlot: Proptypes.bool,
  editOption: Proptypes.bool,
  actionOption: Proptypes.bool,
  downloadFunction: Proptypes.bool,
  setSelectedComponent: Proptypes.func,
  graphDownloading: Proptypes.bool,
};

export default SlotDetails;
