import React from 'react';
import Proptypes from 'prop-types';

import { Bottomkeys } from '../../../../constants';

import {
  ActicleContentDescription,
  ActicleContentTitle,
  ArticleContentWrap,
  ArticleDescriptionWrap,
  ArticleIcon,
  ArticleImageWrap,
  ArticleLink,
  ArticleLinkWrp,
  ArticleWrap,
  ArticleWrapContainer,
  Articlekeytxt,
  ArticlewprZ,
  BoxArticlewpr,
  ToolBarWrp,
} from './index.sc';
import ToolBar from '../add-item-component/toolbar';

const NewsArticle = ({ articles = [], setNewsLetter, newsLetter, rowId }) => {
  const updateComponentDataForRow = (rowId, newComponentData) => {
    const rowIndex = newsLetter.findIndex((row) => row.rowId === rowId);
    if (rowIndex !== -1) {
      const updatedNewsLetter = [...newsLetter];
      updatedNewsLetter[rowIndex].rowItems[0].componentData = newComponentData;
      setNewsLetter(updatedNewsLetter);
    }
  };

  const arrowClick = (row, direction) => {
    const temp = [...articles];
    if (direction === 'up' && row <= 0) {
      return temp;
    } else if (direction === 'down' && row >= temp.length) {
      return temp;
    } else {
      const itemIndex = row; // Index of the item you want to change
      const newIndex = direction === 'up' ? row - 1 : row + 1; // Desired new index for the item
      const item = temp[itemIndex]; // Retrieve the item
      temp.splice(itemIndex, 1); // Remove the item from the original index
      console.log(newIndex);
      temp.splice(newIndex, 0, item); // Insert the item at the new index
    }
    updateComponentDataForRow(rowId, temp);
  };

  const onDelete = (row) => {
    const temp = [...articles];
    const itemIndex = row; // Index of the item you want to change
    temp.splice(itemIndex, 1); // Remove the item from the original index
    updateComponentDataForRow(rowId, temp);
  };
  return (
    <ArticleWrapContainer>
      {articles?.map((ele, i) => (
        <ArticleWrap key={i}>
          <ArticleContentWrap>
            <ArticlewprZ>
              <ArticleLinkWrp>
                {Bottomkeys.map((item, i) => (
                  <BoxArticlewpr key={i}>
                    {item.value === 'link' ? (
                      <>
                        <ArticleIcon src={ele.icon} />
                        <ArticleLink
                          to={ele[item.value]}
                          target="_blank"
                          rel={ele.title}
                        >
                          {ele.link}
                        </ArticleLink>
                      </>
                    ) : (
                      <Articlekeytxt>
                        , {ele[item.value] || item.label}
                      </Articlekeytxt>
                    )}
                  </BoxArticlewpr>
                ))}
              </ArticleLinkWrp>
            </ArticlewprZ>
            <ArticleDescriptionWrap>
              <ActicleContentTitle>{ele.title}</ActicleContentTitle>
              <ActicleContentDescription>
                {ele.content}
              </ActicleContentDescription>
            </ArticleDescriptionWrap>
          </ArticleContentWrap>
          <ArticleImageWrap imageUrl={ele.image}></ArticleImageWrap>
          {articles.length === 1 ? (
            <></>
          ) : (
            <ToolBarWrp className="article-tool-bar-wrp">
              <ToolBar
                arrowClick={(direction) => arrowClick(i, direction)}
                deleteClick={() => onDelete(i, rowId)}
                type={'article'}
              />
            </ToolBarWrp>
          )}
        </ArticleWrap>
      ))}
    </ArticleWrapContainer>
  );
};

NewsArticle.propTypes = {
  articles: Proptypes.any,
  setNewsLetter: Proptypes.func,
  rowId: Proptypes.number,
  newsLetter: Proptypes.any,
};

export default NewsArticle;
