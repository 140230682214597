import * as d3 from 'd3';

export const onClickFunc = (selected, className, config) => {
  // Sujay bhaiya's code
  const classSelected = selected.selectAll(`.${className}`);
  classSelected.style('cursor', 'pointer').on('click', function (event, d) {
    const siblings = selected.selectAll(`.${className}`);
    siblings.classed('unselected', true);
    d3.select(this).node().classList.remove('unselected');
    d3.select(this).node().classList.add('selected');
    config.handleOnClick(event, d);
    event.stopPropagation();
  });
};

export const onMouseOverOut = (selected, className, overFunc, outFunc) => {};

export const onMouseEnterMoveLeave = (
  selected,
  className,
  enterFunc,
  moveFunc,
  leaveFunc
) => {
  selected
    .selectAll(`.${className}`)
    .style('cursor', 'pointer')
    .on('mouseenter', function (event, d) {
      const hoveredElement = d3.select(this);

      const siblings = selected.selectAll(`.${className}`);
      siblings.classed('hover-unselected', true);
      selected
        .selectAll(`.${className}.selected`)
        .classed('hover-unselected', false);
      hoveredElement
        .classed('hover-unselected', false)
        .classed('hover-selected', true);

      enterFunc(event, d);
    })
    .on('mousemove', moveFunc)
    .on('mouseleave', function (event, d) {
      const siblings = selected.selectAll(`.${className}`);
      siblings
        .classed('hover-unselected', false)
        .classed('hover-selected', false);
      leaveFunc(event, d);
    });
};

export const setUpEvents = (config, selected, className) => {
  setTimeout(function () {
    if (config.handleOnClick) {
      onClickFunc(selected, className, config);
    }

    if (config.handleMouseOver && config.handleMouseOut) {
      onMouseOverOut(
        selected,
        className,
        config.handleMouseOver,
        config.handleMouseOut
      );
    }

    if (
      config.handleMouseEnter &&
      config.handleMouseLeave &&
      config.handleMouseMove
    ) {
      onMouseEnterMoveLeave(
        selected,
        className,
        config.handleMouseEnter,
        config.handleMouseMove,
        config.handleMouseLeave
      );
    }
  }, 1000);
};
