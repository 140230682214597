import React, { useEffect, useState, useRef } from 'react';
import PropTypes, { object } from 'prop-types';
import {
  // BackDrop,
  DashboardInnerSection,
  FullSlot,
  HalfSlot,
  SlotWrp,
  // UberTextTitle,
  UberTilesWrp,
  UserTilesMainWrp,
} from '../index.sc';
import SlotDetails from '../slot-details';
import UberTiles from '../../uber-tiles';
import {
  brandWidgets,
  industryWidgets,
  overviewWidgets,
  people,
  standardDashboards,
} from '../../../constants/widgets';
import ResultOverTime from './result-over-time';
import SlotPlaceHolder from '../slot-details/SlotPlaceHolder';
import { axiosGet } from '../../../service';
import { useLocation, useParams } from 'react-router-dom';
import { baseURL } from '../../../constants';
import { useQuery } from '@tanstack/react-query';

const noLegends = ['top_source', 'top_author', 'volume_analysis'];

const DashboardInnerContainer = ({
  dashboardDetails,
  loader,
  tileDetails,
  setArticleType,
  articleType,
  overRideSlot = false,
  articlePosition,
  handleUpdatedChart = () => {},
  customWidgetDetails,
  setClickedPosition,
  resetSelection,
  setResetSelection,
  selected,
  setSelected,
  selectGraph,
  setSelectedGraph,
  handleShowDownloadPopUp = () => {},
  dashboardType,
  customCanvas,
  editOption = false,
  actionOption = true,
  showChangeGraphOptions = true,
  activeScreen = '',
}) => {
  const x = dashboardDetails.length;
  const location = useLocation();
  const [data, setData] = useState(new Array(x).fill(true));
  const [selectedComponent, setSelectedComponent] = useState('');
  const parentDivRef = useRef(null);
  const handleChildClick = (scrollPosition) => {
    if (location.pathname.includes('search-results')) {
      scrollPosition--;
    }
    const childDivId = `childDiv-${scrollPosition}`;
    const childDiv = document.getElementById(childDivId);

    if (childDiv) {
      childDiv.scrollIntoView({ block: 'center' });
    }
  };
  // const handleClick = (i, e) => {
  //   e.stopPropagation();
  //   if (setArticleType) {
  //     setArticleType((prev) => ({
  //       ...prev,
  //       widget: dashboardDetails[i]?.title,
  //     }));
  //     setSelected(i);
  //     setClickedPosition(i);
  //   }
  // };
  const { searchId, dashboardId } = useParams();

  const getDownloadPdf = () => {
    return axiosGet('/downloadPdf', {
      page: 'single-graph',
      dashboardType,
      searchId,
      dashboardId,
      component: selectedComponent,
    });
  };

  const { refetch, isFetching: graphDownloading } = useQuery({
    queryKey: ['download-graph-pdf'],
    queryFn: () => getDownloadPdf(),
    refetchOnWindowFocus: false,
    enabled: false,
    onSuccess: (data) => {
      window.open(`${baseURL}/images/${data.data.pdfName}`, '_blank');
    },
  });

  const onDownload = () => {
    refetch();
  };

  const handleOnClick = (event, d, widget, i) => {
    setResetSelection(false);
    setSelected(i);
    setArticleType((prev) => ({
      ...prev,
      widget: widget.title,
      graphSelection:
        d?.label ||
        d?.labelText ||
        d?.data?.rawdata?.label ||
        d?.properties?.NAME ||
        '',
    }));
    setClickedPosition(i);
    handleChildClick(i);
  };
  useEffect(() => {
    if (selected === null) {
      setData(new Array(x).fill(true));
    }
  }, [x, selected]);
  useEffect(() => {
    if (selected !== null) {
      setData((prev) => {
        return prev?.map((item, index) => (index === selected ? !item : true));
      });
    } else {
      setData((prev) => prev.map((_) => true));
    }
  }, [selected, resetSelection, setSelectedGraph]);
  useEffect(() => {
    if (!articleType?.widget && !articleType?.graphSelection) {
      setResetSelection(true);
    }
  }, [articleType, setResetSelection]);
  return (
    <DashboardInnerSection
      ref={parentDivRef}
      id="download-content"
      className="dash-scroll-container"
      articlePosition={articlePosition}
      activeScreen={activeScreen}
      // selected={selected !== null}   //enable this line to make the graphs not scroll - Sujay
    >
      {tileDetails && (
        <UserTilesMainWrp id={'childDiv-01'}>
          {/* <UberTextTitle>Results In Figure</UberTextTitle> */}
          <UberTilesWrp activeScreen={activeScreen}>
            {tileDetails.map((tile, i) => (
              <UberTiles
                key={i}
                data={tile.data}
                change={tile.change}
                isIncreased={tile.isIncreased}
                title={tile.title}
              />
            ))}
          </UberTilesWrp>
        </UserTilesMainWrp>
      )}
      <SlotWrp>
        {/* {selected !== null && (
          <BackDrop
            onClick={(e) => {
              e.stopPropagation();
              setSelected(null);
            }}
          />
        )} */}
        {customWidgetDetails && (
          <FullSlot
            className="graph-widget"
            id={'childDiv-02'}
            // key={`custom-widget-${i}`}
            selected={selected === 0}
            // onClick={(e) => handleClick(0, e)}
          >
            <ResultOverTime
              widget={customWidgetDetails}
              loader={loader}
              legend={true}
              commentary={true}
              handleOnClick={(event, d, widget) =>
                handleOnClick(event, d, widget, 0)
              }
              resetSelection={resetSelection}
              actionOption={actionOption}
              showChangeGraphOptions={showChangeGraphOptions}
              downloadFunction={onDownload}
              setSelectedComponent={setSelectedComponent}
              graphDownloading={graphDownloading}
            />
          </FullSlot>
        )}
        {dashboardDetails?.length
          ? dashboardDetails.map((widget, j) => {
              const idx = customWidgetDetails ? j + 1 : j;
              if (
                overviewWidgets[widget.component]?.slotType === 'full' ||
                overRideSlot
              ) {
                return (
                  <FullSlot
                    className="graph-widget"
                    key={`widget-${idx}`}
                    selected={idx === selected}
                    id={`childDiv-${j}`}
                    // onClick={(e) => handleClick(idx, e)}
                  >
                    <SlotDetails
                      widget={widget}
                      loader={loader}
                      selected={idx === 0}
                      legend={!noLegends.includes(widget?.component)}
                      commentary={true}
                      handleOnClick={(event, d) =>
                        handleOnClick(event, d, widget, idx)
                      }
                      handleUpdatedChart={handleUpdatedChart}
                      resetSelection={resetSelection}
                      overRideSlot={overRideSlot}
                      editOption={editOption}
                      actionOption={actionOption}
                      handleShowDownloadPopUp={handleShowDownloadPopUp}
                      dashboardType={dashboardType}
                      downloadFunction={onDownload}
                      setSelectedComponent={setSelectedComponent}
                      graphDownloading={graphDownloading}
                    />
                  </FullSlot>
                );
              } else {
                return (
                  <HalfSlot
                    className="graph-widget"
                    key={`widget-${idx}`}
                    selected={idx === selected}
                    id={`childDiv-${j}`}
                    // onClick={(e) => handleClick(idx, e)}
                  >
                    <SlotDetails
                      widget={widget}
                      loader={loader}
                      legend={!noLegends.includes(widget?.component)}
                      commentary={true}
                      handleOnClick={(event, d) => {
                        handleOnClick(event, d, widget, idx);
                      }}
                      handleUpdatedChart={handleUpdatedChart}
                      resetSelection={data[idx]}
                      editOption={editOption}
                      actionOption={actionOption}
                      dashboardType={dashboardType}
                      setSelectedComponent={setSelectedComponent}
                      downloadFunction={onDownload}
                      graphDownloading={graphDownloading}
                    />
                  </HalfSlot>
                );
              }
            })
          : customCanvas && Object.keys(customCanvas).length > 0
          ? Object.keys(customCanvas).map((board, i) => {
              const dashboardWidget = standardDashboards.filter(
                (ele) => ele.value === board
              )[0].widgets;
              return (
                <>
                  <>
                    {Object.keys(customCanvas[board]).map((item, j) => {
                      const idx = customWidgetDetails ? j + 1 : j;
                      if (!customCanvas[board][item]) return <></>;
                      if (dashboardWidget[item]?.slotType === 'full') {
                        return (
                          <FullSlot
                            className="graph-widget"
                            key={`widget-${idx}`}
                            selected={idx === selected}
                            // onClick={(e) => handleClick(idx, e)}
                          >
                            <SlotPlaceHolder
                              body={dashboardWidget[item]?.placeholder}
                              title={dashboardWidget[item]}
                            />
                          </FullSlot>
                        );
                      } else {
                        return (
                          <HalfSlot
                            className="graph-widget"
                            key={`widget-${idx}`}
                            selected={idx === selected}
                            // onClick={(e) => handleClick(idx, e)}
                          >
                            <SlotPlaceHolder
                              body={dashboardWidget[item]?.placeholder}
                              title={dashboardWidget[item]?.label}
                            />
                          </HalfSlot>
                        );
                      }
                    })}
                  </>
                </>
              );
            })
          : dashboardPlaceHolderMap[dashboardType] &&
            Object.keys(dashboardPlaceHolderMap[dashboardType]).map(
              (item, j) => {
                const idx = customWidgetDetails ? j + 1 : j;
                if (
                  dashboardPlaceHolderMap[dashboardType][item].slotType ===
                  'full'
                ) {
                  return (
                    <FullSlot
                      className="graph-widget"
                      key={`widget-${idx}`}
                      selected={idx === selected}
                      // onClick={(e) => handleClick(idx, e)}
                    >
                      <SlotPlaceHolder
                        body={
                          dashboardPlaceHolderMap[dashboardType][item]
                            .placeholder
                        }
                        title={dashboardPlaceHolderMap[dashboardType][item]}
                      />
                    </FullSlot>
                  );
                } else {
                  return (
                    <HalfSlot
                      className="graph-widget"
                      key={`widget-${idx}`}
                      selected={idx === selected}
                      // onClick={(e) => handleClick(idx, e)}
                    >
                      <SlotPlaceHolder
                        body={
                          dashboardPlaceHolderMap[dashboardType][item]
                            .placeholder
                        }
                        title={
                          dashboardPlaceHolderMap[dashboardType][item].label
                        }
                      />
                    </HalfSlot>
                  );
                }
              }
            )}
      </SlotWrp>
    </DashboardInnerSection>
  );
};

DashboardInnerContainer.propTypes = {
  dashboardDetails: PropTypes.arrayOf(object),
  tileDetails: PropTypes.arrayOf(object),
  selected: PropTypes.any,
  loader: PropTypes.bool,
  setSelected: PropTypes.func,
  setArticleType: PropTypes.func,
  articleType: PropTypes.object,
  overRideSlot: PropTypes.bool,
  setClickedPosition: PropTypes.func,
  articlePosition: PropTypes.string,
  handleUpdatedChart: PropTypes.func,
  customWidgetDetails: PropTypes.object,
  resetSelection: PropTypes.bool,
  editOption: PropTypes.bool,
  actionOption: PropTypes.bool,
  setResetSelection: PropTypes.func,
  setArticleTypeClose: PropTypes.func,
  setArticlePosition: PropTypes.func,
  selectGraph: PropTypes.array,
  setSelectedGraph: PropTypes.any,
  handleShowDownloadPopUp: PropTypes.func,
  dashboardType: PropTypes.string,
  customCanvas: PropTypes.object,
  showChangeGraphOptions: PropTypes.bool,
  activeScreen: PropTypes.string,
};

export default DashboardInnerContainer;

const dashboardPlaceHolderMap = {
  brand: brandWidgets,
  industry: industryWidgets,
  people,
};
