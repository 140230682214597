import React from 'react';

const ListView = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          d="M19.0195 5.09863H5.01953C4.46725 5.09863 4.01953 5.54635 4.01953 6.09863C4.01953 6.65092 4.46725 7.09863 5.01953 7.09863H19.0195C19.5718 7.09863 20.0195 6.65092 20.0195 6.09863C20.0195 5.54635 19.5718 5.09863 19.0195 5.09863Z"
          fill="white"
        />
        <path
          d="M19.0195 5.59863H5.01953C4.74339 5.59863 4.51953 5.82249 4.51953 6.09863C4.51953 6.37478 4.74339 6.59863 5.01953 6.59863H19.0195C19.2957 6.59863 19.5195 6.37478 19.5195 6.09863C19.5195 5.82249 19.2957 5.59863 19.0195 5.59863Z"
          stroke="black"
        />
        <path
          d="M19.0195 8.09863H5.01953C4.46725 8.09863 4.01953 8.54635 4.01953 9.09863C4.01953 9.65092 4.46725 10.0986 5.01953 10.0986H19.0195C19.5718 10.0986 20.0195 9.65092 20.0195 9.09863C20.0195 8.54635 19.5718 8.09863 19.0195 8.09863Z"
          fill="white"
        />
        <path
          d="M19.0195 8.59863H5.01953C4.74339 8.59863 4.51953 8.82249 4.51953 9.09863C4.51953 9.37478 4.74339 9.59863 5.01953 9.59863H19.0195C19.2957 9.59863 19.5195 9.37478 19.5195 9.09863C19.5195 8.82249 19.2957 8.59863 19.0195 8.59863Z"
          stroke="black"
        />
        <path
          d="M19.0195 11.0986H5.01953C4.46725 11.0986 4.01953 11.5463 4.01953 12.0986C4.01953 12.6509 4.46725 13.0986 5.01953 13.0986H19.0195C19.5718 13.0986 20.0195 12.6509 20.0195 12.0986C20.0195 11.5463 19.5718 11.0986 19.0195 11.0986Z"
          fill="white"
        />
        <path
          d="M19.0195 11.5986H5.01953C4.74339 11.5986 4.51953 11.8225 4.51953 12.0986C4.51953 12.3748 4.74339 12.5986 5.01953 12.5986H19.0195C19.2957 12.5986 19.5195 12.3748 19.5195 12.0986C19.5195 11.8225 19.2957 11.5986 19.0195 11.5986Z"
          stroke="black"
        />
        <path
          d="M19.0195 14.0986H5.01953C4.46725 14.0986 4.01953 14.5463 4.01953 15.0986C4.01953 15.6509 4.46725 16.0986 5.01953 16.0986H19.0195C19.5718 16.0986 20.0195 15.6509 20.0195 15.0986C20.0195 14.5463 19.5718 14.0986 19.0195 14.0986Z"
          fill="white"
        />
        <path
          d="M19.0195 14.5986H5.01953C4.74339 14.5986 4.51953 14.8225 4.51953 15.0986C4.51953 15.3748 4.74339 15.5986 5.01953 15.5986H19.0195C19.2957 15.5986 19.5195 15.3748 19.5195 15.0986C19.5195 14.8225 19.2957 14.5986 19.0195 14.5986Z"
          stroke="black"
        />
        <path
          d="M19.0195 17.0986H5.01953C4.46725 17.0986 4.01953 17.5463 4.01953 18.0986C4.01953 18.6509 4.46725 19.0986 5.01953 19.0986H19.0195C19.5718 19.0986 20.0195 18.6509 20.0195 18.0986C20.0195 17.5463 19.5718 17.0986 19.0195 17.0986Z"
          fill="white"
        />
        <path
          d="M19.0195 17.5986H5.01953C4.74339 17.5986 4.51953 17.8225 4.51953 18.0986C4.51953 18.3748 4.74339 18.5986 5.01953 18.5986H19.0195C19.2957 18.5986 19.5195 18.3748 19.5195 18.0986C19.5195 17.8225 19.2957 17.5986 19.0195 17.5986Z"
          stroke="black"
        />
      </g>
    </svg>
  );
};

export default ListView;
