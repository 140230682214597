import React, { useEffect, useState } from 'react';
import {
  NewsLetterTextItemInput,
  NewsLetterTextItemdescription,
  TextContent,
  TextContentWrp,
  TextHeading,
} from './index.sc';
import Proptypes from 'prop-types';

const TextItem = ({
  title,
  description,
  editMode,
  activeElement,
  handleElementClick,
  handleBlur,
  setNewsLetter,
  rowId,
  newsLetter,
}) => {
  const [textItemDetails, setTextItemDetails] = useState({});
  useEffect(() => {
    setTextItemDetails({
      title,
      description,
    });
  }, [title, description]);

  const updateComponentDataForRow = (rowId, newComponentData) => {
    const rowIndex = newsLetter.findIndex((row) => row.rowId === rowId);
    if (rowIndex !== -1) {
      const updatedNewsLetter = [...newsLetter];
      updatedNewsLetter[rowIndex].rowItems[0].componentData = newComponentData;
      setNewsLetter(updatedNewsLetter);
    }
  };
  console.log(newsLetter, 'this');

  const changeTextDetails = (e, rowId) => {
    const { name, value } = e.target;
    setTextItemDetails((prevDetails) => ({ ...prevDetails, [name]: value }));

    const temp = { ...textItemDetails, [name]: value };
    updateComponentDataForRow(temp, rowId);
  };

  return (
    <TextContentWrp>
      {editMode && activeElement === `text-title-${rowId}` ? (
        <NewsLetterTextItemInput
          name="title"
          value={textItemDetails.title}
          onChange={(e) => changeTextDetails(e, rowId)}
          onBlur={handleBlur}
          autoFocus
        />
      ) : (
        <TextHeading
          onClick={() => {
            handleElementClick(`text-title-${rowId}`);
          }}
        >
          {textItemDetails.title}
        </TextHeading>
      )}
      {editMode && activeElement === `text-desc-${rowId}` ? (
        <NewsLetterTextItemdescription
          name="description"
          value={textItemDetails.description}
          onChange={(e) => changeTextDetails(e, rowId)}
          onBlur={handleBlur}
          autoFocus
        ></NewsLetterTextItemdescription>
      ) : (
        <TextContent
          onClick={() => {
            handleElementClick(`text-desc-${rowId}`);
          }}
        >
          {textItemDetails.description}
        </TextContent>
      )}
    </TextContentWrp>
  );
};

TextItem.propTypes = {
  title: Proptypes.string,
  description: Proptypes.string,
  editMode: Proptypes.bool,
  activeElement: Proptypes.string,
  handleElementClick: Proptypes.func,
  handleBlur: Proptypes.func,
  setNewsLetter: Proptypes.func,
  rowId: Proptypes.number,
  newsLetter: Proptypes.any,
};

export default TextItem;
