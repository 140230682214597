import React, { useEffect, useState } from 'react';
import * as d3 from 'd3';
import styled from 'styled-components';
import { initialValues } from '../../graphs/utils/graphConst.js';
import WordCloud from '../../graphs/WordCloud/wc.js';
import parseText from './wc.js';

const GraphWrp = styled.div`
  width: 65rem;
  height: 25rem;
  margin: auto;
`;

// Define the handleOnClick function
const handleOnClick = (item) => {
  console.log('Clicked:', item);
};
const handleMouseEnter = (event, item) => {
  console.log('Mouse entered:', item);
};

const handleMouseMove = (event, item) => {
  console.log('Mouse moved:', item);
};

const handleMouseLeave = (event, item) => {
  console.log('Mouse left:', item);
};

const config = {
  ...initialValues,
  handleOnClick,
  handleMouseEnter,
  handleMouseMove,
  handleMouseLeave,
};

const GraphTest = () => {
  const [data, setData] = useState(null);

  useEffect(() => {
    d3.text('./words.txt').then(parseText).then(setData);
  }, []);

  return (
    <GraphWrp>
      <div className="wordcloud item-b ">
        <svg width="800" height="800">
          <WordCloud
            words={data}
            // forCarol={chosenCarol}
            width={800}
            height={200}
            angle={0}
            fontSizeLarge={45}
            fontSizeSmall={10}
            showCounts={false}
            countSize={5}
            config={config}
          />
        </svg>
      </div>
    </GraphWrp>
  );
};

export default GraphTest;
