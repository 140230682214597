import styled from 'styled-components';
export const DrawerContentBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 0.75rem;
  max-height: 90vh;
  background-color: rgb(236, 239, 243);
`;

export const Headerwrap = styled.div`
  box-sizing: border-box;
  display: flex;
  width: 100%;
  padding: ${({ addArticle }) => (addArticle ? '0 0 1rem 0' : '1rem 1.875rem')};
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  position: relative;
  border-radius: 0.625rem 0.625rem 0 0;
`;

export const Headerleftwpr = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
  & > img {
    width: 1.5rem;
    height: 1.5rem;
    align-items: center;
  }
`;

export const Heaerlblwrp = styled.div`
  font-size: 1.25rem;
  font-family: Inter;
  color: ${({ theme }) => theme.secondaryText};
  font-style: normal;
  font-weight: 700;
`;

export const MainBoxwpr = styled.div`
  height: 31.25rem;
  display: flex;
  gap: 1rem;
  flex-direction: column;
  overflow: auto;
  &::-webkit-scrollbar {
    display: none;
  }
  background: rgb(236, 239, 243);
  padding: 1.75rem;
`;

export const FooterBoxwpr = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 0.75rem 1.85rem;
  margin-top: ${({ mt }) => `${mt}rem`};
  background: #ffffff;
  border-radius: 0 0 0.625rem 0.625rem;
  /* box-shadow: rgb(195, 199, 217) 0px -1px 0px 0px; */
  z-index: 10;
`;

export const LeftfootBoxwpr = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-weight: 400;
  font-size: 14px;
  display: flex;
  align-items: flex-end;
  color: #999999;
  & span {
    font-weight: 600;
    font-size: 0.75rem;
    color: ${({ theme }) => theme.secondaryText};
  }
  &:hover {
    cursor: pointer;
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
`;

export const Iconwpr = styled.div`
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  cursor: pointer;
  & > img {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
`;

export const PoptabTitle = styled.div`
  font-size: 0.9375rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.125rem;
  letter-spacing: -0.01875rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 0.6rem 0.25rem;
`;

export const TabsBox = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: space-between;
`;

export const HeadingBoxwpr = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
  & > span {
    font-weight: 400;
    font-size: 0.7rem;
    color: #818181;
  }
  & .yshj {
    font-weight: 600;
    font-size: 0.7rem;
    color: ${({ theme }) => theme.secondaryText};
    & > span {
      font-weight: 400;
      font-size: 0.7rem;
      color: #818181;
      margin-left: 3px;
    }
  }
  padding: 1rem;
  background: #ffffff;
  border-top-left-radius: 0.625rem;
  border-top-right-radius: 0.625rem;
`;

export const ItemContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 0.5rem;
`;

export const ListBoxwpr = styled.div`
  width: 100%;
  overflow: auto;
  &::-webkit-scrollbar {
    display: none;
  }
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const SortInfotxt = styled.div`
  font-size: 0.7rem;
  font-weight: 400;
  color: ${({ theme }) => theme.secondaryText};
  margin: 0.25rem;
`;

export const ListContainer = styled.div`
  display: flex;
  box-sizing: border-box;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1.25rem;
  border: 1px solid #c3c7d9;
  width: 100%;
  border-radius: 0.62rem;
`;

export const ListLeft = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const ListDiscwpr = styled.div`
  color: ${(props) => props.color};
  font-weight: ${(props) => props.fontWeight};
  font-size: 0.7rem;
  margin-right: 0.1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

export const WriteIconwpr = styled.div`
  position: absolute;
  right: 1rem;
  top: 1rem;
`;
