import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { formatNumber } from '../../utils';
import {
  Change,
  Tile,
  TileData,
  TileDataWrp,
  TileTitle,
  TooltipTitle,
  TooltipValue,
  TooltipWrapper,
} from './index.sc';
import ArrowIncrease from '../../assets/icons/ArrowIncrease';
import ArrowDecrease from '../../assets/icons/ArrowDecrease';
import PortalTooltip from '../portal-tooltip';

const SummaryTooltip = ({
  tooltipData = { label: '', value: '', isIncreased: null, change: '' },
}) => {
  const {
    label = '',
    value = '',
    isIncreased = null,
    change = '',
  } = tooltipData;
  return (
    <TooltipWrapper>
      <TooltipTitle>{label}</TooltipTitle>
      <TooltipValue>
        {label === 'Total AVE' && '~$'}
        {value?.toLocaleString('en-US')}
      </TooltipValue>
      <Change isIncreased={isIncreased} isToolTip={true}>
        {isIncreased ? <ArrowIncrease /> : <ArrowDecrease />}
        {change}%
      </Change>
    </TooltipWrapper>
  );
};

const UberTiles = ({
  title,
  data,
  isIncreased,
  change,
  handleClick = () => {},
}) => {
  const { value, midSuffix } = formatNumber(data, true, true);
  const [showTooltip, setShowTooltip] = useState(false);
  const [toolTipPos, setToolTipPos] = useState({ left: 0, top: 0 });
  const [tooltipData, setTooltipData] = useState();

  const handleMouseEnter = (event) => {
    console.log(isIncreased, change, 'data for uber tiles');
    setShowTooltip(true);
    setToolTipPos({
      ...toolTipPos,
      left: event.clientX,
      top: event.clientY - 10,
    });
    setTooltipData({
      label: title,
      value: data,
      isIncreased,
      change,
    });
  };

  const handleMouseMove = (event) => {
    setToolTipPos({
      ...toolTipPos,
      left: event.clientX,
      top: event.clientY - 10,
    });
  };

  const handleMouseLeave = (e) => {
    setToolTipPos({
      left: 0,
      top: 0,
    });
    setTooltipData();
    setShowTooltip(false);
  };

  return (
    <Tile onClick={handleClick}>
      <TileDataWrp
        onMouseEnter={handleMouseEnter}
        onMouseMove={handleMouseMove}
        onMouseLeave={handleMouseLeave}
      >
        <TileData>
          {title === 'Total AVE' && '~$'}
          {value} {midSuffix}
        </TileData>
        <Change isIncreased={isIncreased} isToolTip={false}>
          {isIncreased ? <ArrowIncrease /> : <ArrowDecrease />}
          {change}%
        </Change>
      </TileDataWrp>
      <TileTitle>{title}</TileTitle>
      {showTooltip && (
        <PortalTooltip
          isOpen={true}
          pos={toolTipPos}
          align={toolTipPos.left > window.innerWidth / 2 ? 'left' : 'right'}
          vAlign={'top'}
        >
          <SummaryTooltip tooltipData={tooltipData} />
        </PortalTooltip>
      )}
    </Tile>
  );
};

UberTiles.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  data: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isIncreased: PropTypes.bool,
  change: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  handleClick: PropTypes.bool,
};

SummaryTooltip.propTypes = {
  tooltipData: PropTypes.object,
};

export default UberTiles;
