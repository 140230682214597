import React from 'react';

export default function LeftFacingArrow() {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 33 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_10545_104808)">
        <path
          d="M27.1888 15.3783H10.9621L18.4155 7.92492L16.5221 6.04492L5.85547 16.7116L16.5221 27.3783L18.4021 25.4983L10.9621 18.0449H27.1888V15.3783Z"
          fill="#656B8A"
        />
      </g>
      <defs>
        <clipPath id="clip0_10545_104808">
          <rect
            width="32"
            height="32"
            fill="white"
            transform="translate(0.523438 0.710938)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
