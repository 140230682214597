import React from 'react';

const GridView = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          d="M8 5H6C5.44772 5 5 5.44772 5 6V8C5 8.55228 5.44772 9 6 9H8C8.55228 9 9 8.55228 9 8V6C9 5.44772 8.55228 5 8 5Z"
          fill="#999999"
        />
        <path
          d="M8 10H6C5.44772 10 5 10.4477 5 11V13C5 13.5523 5.44772 14 6 14H8C8.55228 14 9 13.5523 9 13V11C9 10.4477 8.55228 10 8 10Z"
          fill="#999999"
        />
        <path
          d="M8 15H6C5.44772 15 5 15.4477 5 16V18C5 18.5523 5.44772 19 6 19H8C8.55228 19 9 18.5523 9 18V16C9 15.4477 8.55228 15 8 15Z"
          fill="#999999"
        />
        <path
          d="M13 5H11C10.4477 5 10 5.44772 10 6V8C10 8.55228 10.4477 9 11 9H13C13.5523 9 14 8.55228 14 8V6C14 5.44772 13.5523 5 13 5Z"
          fill="#999999"
        />
        <path
          d="M13 10H11C10.4477 10 10 10.4477 10 11V13C10 13.5523 10.4477 14 11 14H13C13.5523 14 14 13.5523 14 13V11C14 10.4477 13.5523 10 13 10Z"
          fill="#999999"
        />
        <path
          d="M13 15H11C10.4477 15 10 15.4477 10 16V18C10 18.5523 10.4477 19 11 19H13C13.5523 19 14 18.5523 14 18V16C14 15.4477 13.5523 15 13 15Z"
          fill="#999999"
        />
        <path
          d="M18 5H16C15.4477 5 15 5.44772 15 6V8C15 8.55228 15.4477 9 16 9H18C18.5523 9 19 8.55228 19 8V6C19 5.44772 18.5523 5 18 5Z"
          fill="#999999"
        />
        <path
          d="M18 10H16C15.4477 10 15 10.4477 15 11V13C15 13.5523 15.4477 14 16 14H18C18.5523 14 19 13.5523 19 13V11C19 10.4477 18.5523 10 18 10Z"
          fill="#999999"
        />
        <path
          d="M18 15H16C15.4477 15 15 15.4477 15 16V18C15 18.5523 15.4477 19 16 19H18C18.5523 19 19 18.5523 19 18V16C19 15.4477 18.5523 15 18 15Z"
          fill="#999999"
        />
      </g>
    </svg>
  );
};

export default GridView;
