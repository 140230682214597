import React from 'react';
import PropTypes from 'prop-types';

export default function ContactIcon({ size }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 71 71"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="35.8262" cy="35.5" r="35" fill="#675EF2" fillOpacity="0.1" />
      <path
        d="M35.8262 24.25C30.3181 24.25 25.8262 28.742 25.8262 34.25V35.5C24.4512 35.5 23.3262 36.625 23.3262 38V40.5C23.3262 41.875 24.4512 43 25.8262 43H28.3262V36.75V35.5V34.25C28.3262 30.093 31.6692 26.75 35.8262 26.75C39.9831 26.75 43.3262 30.093 43.3262 34.25V35.5V36.75V43V44.25H38.2163C38.0872 43.8849 37.8482 43.5687 37.5321 43.3448C37.2161 43.121 36.8385 43.0005 36.4512 43C35.9539 43 35.477 43.1975 35.1253 43.5492C34.7737 43.9008 34.5762 44.3777 34.5762 44.875C34.5768 45.3486 34.7566 45.8044 35.0795 46.1509C35.4025 46.4973 35.8445 46.7088 36.3169 46.7427C36.3615 46.7475 36.4063 46.75 36.4512 46.75H43.3262C44.6925 46.75 45.8262 45.6163 45.8262 44.25V43C47.2012 43 48.3262 41.875 48.3262 40.5V38C48.3262 36.625 47.2012 35.5 45.8262 35.5V34.25C45.8262 28.742 41.3342 24.25 35.8262 24.25Z"
        fill="#675EF2"
      />
    </svg>
  );
}

ContactIcon.propTypes = {
  size: PropTypes.string,
};
