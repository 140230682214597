// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rdrStartEdge {
  background-color: #675ef2;
}
.rdrEndEdge {
  background-color: #675ef2;
}
.rdrInRange {
  background-color: #675ef2;
}

.rdrDateDisplayItemActive {
  box-shadow: 0 1px 2px 0 rgba(103, 94, 242, 0.21);
}
.rdrCalendarWrapper {
  font-size: 0.75rem;
  border: 1px solid #dedede;
}
.rdrDateDisplayItem input {
  height: 2.5rem;
  line-height: 2.5rem;
  font-size: 0.8rem;
}
.rdrDayToday .rdrDayNumber span:after {
  background-color: #675ef2;
}
.rdrDateDisplay {
  color: #675ef2 !important;
}
.rdrMonthPicker option,
.rdrYearPicker option {
  background-color: #fff;
  padding: 0.5rem;
}
`, "",{"version":3,"sources":["webpack://./src/components/Calendar/Calendar.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;AAC3B;AACA;EACE,yBAAyB;AAC3B;AACA;EACE,yBAAyB;AAC3B;;AAEA;EACE,gDAAgD;AAClD;AACA;EACE,kBAAkB;EAClB,yBAAyB;AAC3B;AACA;EACE,cAAc;EACd,mBAAmB;EACnB,iBAAiB;AACnB;AACA;EACE,yBAAyB;AAC3B;AACA;EACE,yBAAyB;AAC3B;AACA;;EAEE,sBAAsB;EACtB,eAAe;AACjB","sourcesContent":[".rdrStartEdge {\n  background-color: #675ef2;\n}\n.rdrEndEdge {\n  background-color: #675ef2;\n}\n.rdrInRange {\n  background-color: #675ef2;\n}\n\n.rdrDateDisplayItemActive {\n  box-shadow: 0 1px 2px 0 rgba(103, 94, 242, 0.21);\n}\n.rdrCalendarWrapper {\n  font-size: 0.75rem;\n  border: 1px solid #dedede;\n}\n.rdrDateDisplayItem input {\n  height: 2.5rem;\n  line-height: 2.5rem;\n  font-size: 0.8rem;\n}\n.rdrDayToday .rdrDayNumber span:after {\n  background-color: #675ef2;\n}\n.rdrDateDisplay {\n  color: #675ef2 !important;\n}\n.rdrMonthPicker option,\n.rdrYearPicker option {\n  background-color: #fff;\n  padding: 0.5rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
