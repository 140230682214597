import React, { useEffect, useRef, useState } from 'react';
import {
  Dot,
  DownloadNumbers,
  IconBoxwpr,
  Iconwpr,
  InputIconWrapper,
  InputSearchBar,
  SearchInputSeaction,
  SearchMainWrraper,
} from './index.sc';
import SearchIcon2 from '../../assets/icons/SearchIcon2';
import IconPop from '../icon-popup';
import DownloadIcon from '../../assets/icons/DownloadIcon';
import FilterIcon from '../../assets/icons/FilterIcon';
import SortIcon from '../../assets/icons/SortIcon';
import PropTypes from 'prop-types';
import SortDropdown from './SortDropdown';
import CircularLoading from '../../assets/icons/loading/circularLoading';
import X from '../../assets/icons/X';
import Tooltip from '../icon-tooltip';
import { theme } from '../../constants/theme';

const downloadList = [
  {
    id: 0,
    label: 'PDF',
  },
  {
    id: 1,
    label: 'Excel',
  },
  {
    id: 2,
    label: 'CSV',
  },
];
export const dropdownOptions = [
  {
    label: 'Relevance',
    value: 'relevance',
  },
  {
    label: 'Data Published',
    value: 'date_published',
  },
  {
    label: 'Reach',
    value: 'reach',
  },
  {
    label: 'AVE',
    value: 'ave',
  },
  {
    label: 'Sentiment',
    value: 'sentiment',
  },
  {
    label: 'Bookmarked',
    value: 'bookmarked',
  },
];
const filterDropDownOptions = [
  {
    label: 'Bookmarked',
    value: 'bookmarked',
  },
  {
    label: 'Relevance',
    value: 'relevance',
  },
];
const DownloadDropDownOptions = [
  {
    label: 'Selected',
    value: 'selected',
  },
  {
    label: 'Bookmarked',
    value: 'bookmarked',
  },
  {
    label: 'Tagged',
    value: 'tagged',
  },
];
const IconBox = ({
  page,
  type,
  searchQuery,
  setSearchQuery,
  sortOrder,
  setSortOrder,
  downLoadFunction,
  downloading,
  filter,
  setFilter,
  selectedLength,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isFilterValue, setIsFilterValue] = useState(false);
  const [sortPopUp, setSortPopUp] = useState(false);
  const [isDownloadPopUp, setDownloadPopUp] = useState(false);
  const [searchKey, setSearchKey] = useState('');
  const inputRef = useRef(null);
  const iconBoxRef = useRef(null);
  const handleClickOutside = (event) => {
    if (iconBoxRef.current && !iconBoxRef.current.contains(event.target)) {
      setIsFilterValue(false);
      setSortPopUp(false);
      setDownloadPopUp(false);
      setIsOpen(false);
    }
  };
  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  // console.log(page, 'this is a page', type, sortOrder);

  const handleDownloadClick = (id) => {
    downLoadFunction();
  };
  const handleSearchIconClick = () => {
    setIsOpen((prevOpen) => !prevOpen);
    setSortPopUp(false);
    setDownloadPopUp(false);
    setIsFilterValue(false);
    setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }, 100);
  };

  const handleSearch = () => {
    setIsOpen((prevOpen) => !prevOpen);
    setSortPopUp(false);
    setIsFilterValue(false);
    setDownloadPopUp(false);
    setSearchQuery(searchKey);
  };
  const handleSortClick = () => {
    setIsFilterValue(false);
    setDownloadPopUp(false);
    setSortPopUp((prevOpen) => !prevOpen);
    setIsOpen(false);
  };

  const handleSort = (value) => {
    setIsOpen(false);
    setSortOrder((prev) => (prev === value ? '' : value));
    setSortPopUp(false);
  };

  const handleFilterClick = () => {
    setIsOpen(false);
    setSortPopUp(false);
    setIsFilterValue((prev) => !prev);
  };
  const handleFilter = (value) => {
    setIsOpen(false);
    setDownloadPopUp(false);
    setFilter((prev) => (prev === value ? '' : value));
    setIsFilterValue(false);
  };

  const handleDownload = () => {
    setIsOpen(false);
    setSortPopUp(false);
    setIsFilterValue(false);
    setDownloadPopUp((prev) => !prev);
  };

  const handleDownloadFn = (value) => {
    downLoadFunction(value);
    setDownloadPopUp(false);
  };

  return (
    <IconBoxwpr ref={iconBoxRef}>
      {isOpen && (
        <SearchInputSeaction isOpen={isOpen}>
          <InputSearchBar
            onChange={(e) => setSearchKey(e.target.value)}
            ref={inputRef}
            value={searchKey}
            type="text"
            placeholder="Search"
          />
          <InputIconWrapper onClick={handleSearch}>
            <X size="20" color="white" />
          </InputIconWrapper>
        </SearchInputSeaction>
      )}

      <Tooltip content="Search">
        <Iconwpr onClick={handleSearchIconClick}>
          <SearchIcon2 color="white" />
        </Iconwpr>
      </Tooltip>

      {false && (
        <IconPop handleClick={handleDownloadClick} Items={downloadList}>
          <Iconwpr>
            <DownloadIcon color="white" />
          </Iconwpr>
        </IconPop>
      )}
      {/* Download Button */}
      <SearchMainWrraper>
        <Tooltip content="Download">
          <Iconwpr onClick={handleDownload} hasSortOrder={filter !== ''}>
            {downloading ? (
              <CircularLoading
                bgColor="#ffffff"
                size="0.25rem"
                width="1rem"
                height="1rem"
              />
            ) : (
              <>
                <DownloadIcon color="white" />
                {selectedLength > 0 && (
                  <DownloadNumbers theme={theme}>
                    {selectedLength}
                  </DownloadNumbers>
                )}
              </>
            )}
            {/* {filter !== '' && <Dot></Dot>} */}
          </Iconwpr>
        </Tooltip>
        <SortDropdown
          Open={isDownloadPopUp}
          setIsDropdownOpen={handleDownloadFn}
          setSortOrder={setDownloadPopUp}
          sortOrder={''}
          dropdownOptions={DownloadDropDownOptions}
        />
      </SearchMainWrraper>
      {/* Filter Button */}
      <SearchMainWrraper>
        <Tooltip content="Filter">
          <Iconwpr onClick={handleFilterClick} hasSortOrder={filter !== ''}>
            <FilterIcon color="white" />
            {filter !== '' && <Dot></Dot>}
          </Iconwpr>
        </Tooltip>
        <SortDropdown
          Open={isFilterValue}
          setIsDropdownOpen={handleFilter}
          setSortOrder={setFilter}
          sortOrder={filter}
          dropdownOptions={filterDropDownOptions}
        />
      </SearchMainWrraper>
      {/* Sorting Button */}
      <SearchMainWrraper>
        <Tooltip content="Sort">
          <Iconwpr onClick={handleSortClick} hasSortOrder={sortOrder !== ''}>
            <SortIcon color="white" />
            {sortOrder !== '' && <Dot></Dot>}
          </Iconwpr>
        </Tooltip>
        <SortDropdown
          Open={sortPopUp}
          setIsDropdownOpen={handleSort}
          setSortOrder={setSortOrder}
          sortOrder={sortOrder}
          setIsOpen={setIsOpen}
          dropdownOptions={dropdownOptions}
        />
      </SearchMainWrraper>
    </IconBoxwpr>
  );
};

IconBox.propTypes = {
  page: PropTypes.number,
  type: PropTypes.string,
  sortOrder: PropTypes.string,
  searchQuery: PropTypes.string,
  setSearchQuery: PropTypes.func,
  setSortOrder: PropTypes.func,
  downLoadFunction: PropTypes.func,
  downloading: PropTypes.bool,
  filter: PropTypes.string,
  setFilter: PropTypes.func,
  selectedLength: PropTypes.number,
};
export default IconBox;
