import React from 'react';
import { GraphIconBoxwpr, Iconwpr } from './index.sc';
import DownloadIcon from '../../assets/icons/DownloadIcon';
import Share from '../../assets/icons/Share';
import PropTypes from 'prop-types';
import CircularLoading from '../../assets/icons/loading/circularLoading';
import AddCanvasIcon from '../../assets/icons/AddCanvasIcon';
import Tooltip from '../icon-tooltip';
const GraphIconBox = ({
  fixed = true,
  downloadClickFunction,
  downloading,
  handleShowAddToCanvas = () => {},
  isAdvanced = false,
}) => {
  return (
    <GraphIconBoxwpr fixed={fixed}>
      <Tooltip content="Download">
        <Iconwpr onClick={downloadClickFunction}>
          {downloading ? (
            <CircularLoading
              bgColor="#ffffff"
              size="0.25rem"
              width="1rem"
              height="1rem"
            />
          ) : (
            <DownloadIcon color="white" />
          )}
        </Iconwpr>
      </Tooltip>

      <Tooltip content="Share">
        <Iconwpr>
          <Share color="white" />
        </Iconwpr>
      </Tooltip>
      {!isAdvanced && (
        <Tooltip content="Add to Canvas">
          <Iconwpr onClick={handleShowAddToCanvas}>
            <AddCanvasIcon color="white" />
          </Iconwpr>
        </Tooltip>
      )}
    </GraphIconBoxwpr>
  );
};

GraphIconBox.propTypes = {
  fixed: PropTypes.bool,
  downloadClickFunction: PropTypes.func,
  downloading: PropTypes.bool,
  handleShowAddToCanvas: PropTypes.func,
  isAdvanced: PropTypes.bool,
};
export default GraphIconBox;
